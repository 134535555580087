import React, { useState, useEffect } from 'react';
import constants from '../../constants/constants';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { TabMenu } from 'primereact/tabmenu';
import { Calendar } from 'primereact/calendar';
import CommentsHod from './commentsHod';
import { Button } from 'primereact/button';
import { Controller, useForm } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { MultiSelect } from 'primereact/multiselect';
import axiosInstance from '../../constants/axiosInstance';

const TaskViewHod = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [userList, setUserList] = useState();
    const [maxStartDate, setMaxStartDate] = useState();
    const statusList = ["Open", "In-Progress", "Completed"];
    const user = useStoreState((actions) => actions.loginModel.user);
    const projectViewDatas = useStoreState((actions) => actions.projectModel.projectViewDatas);
    const toast = useRef(null);
    let subTask = props?.subTaskDetail;
    console.log(props?.headerDetails);


    const header = (
        <div className="head-1">
            <h2 className="head-2">Sub Task Detail</h2>
        </div>
    );

    const setActiveIndex = useStoreActions((actions) => actions.tabModel.setActiveIndex);
    const activeIndex = useStoreState((actions) => actions.tabModel.activeIndex);

    const items = [
        // { label: 'All' },
        { label: 'Comment' },
        // { label: 'History' },
    ];

    useEffect(() => {
        setActiveIndex(1);
        getUserList();
    }, []);

    const getUserList = () => {
        setIsLoading(true);
        axiosInstance
            .get(constants.URL.USERS + "?page=1&limit=1000&sort_by=-created_at")
            .then((resp) => {
                setUserList(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const defaultValues = { subTaskName: "", subTaskDescription: "", subTaskKeyPointers: "", assignTo: "", fileAttachment: "", startDate: "", endDate: "", subTaskRemarks: "", subTaskStatus: "", fileViewer:"", department:'', };
    const form = useForm({ defaultValues });
    const { reset, setValue } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    useEffect(() => {
        if (subTask) {
            setValue("subTaskName", subTask?.subTaskName)
            setValue("subTaskDescription", subTask?._id?.subTaskDescription)
            setValue("subTaskKeyPointers", subTask?.subTaskKeyPointers)
            setValue("assignTo", subTask?.assignTo?._id)
            setValue("subTaskStatus", subTask?.subTaskStatus ? subTask?.subTaskStatus : "Open")
            setValue("startDate", subTask?.startDate ? new Date(subTask?.startDate) : null)
            setValue("endDate", subTask?.endDate ? new Date(subTask?.endDate) : null)
            setValue("fileAttachment", subTask?.fileAttachment === true ? "Yes" : "No")
            setValue("subTaskRemarks", subTask?.subTaskRemarks)
            setValue("subTaskDepartment", subTask?.subTaskDepartment)
            setValue("fileViewer", subTask?.fileViewers_id || [])
        }
    }, [subTask]);

    const onSubmitTask = (data) => {
        const payload = {
            subTaskName: data.subTaskName,
            subTaskDescription: data.subTaskDescription,
            assignTo: data.assignTo,
            startDate: data.startDate,
            endDate: data.endDate,
            fileViewers_id:data.fileViewer,
        }
        
        setIsLoading(true);
        axiosInstance.patch(constants.APQP_UPL.PROJECTS + "/" + projectViewDatas?._id + "/taskHeader/" + props?.headerDetails?._id?._id + "/subtask/" + subTask?._id?._id, payload)
            .then((resp) => {
                setActiveIndex(1)
                props.sendDataToParent("API Call & Redirection"); // Sending data back to the parent
                // toast.current.show({ severity: "success", summary: "Success", detail: "Updated Successfully" });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <div>
            <Toast ref={toast} />
            <div>
                <div className="grid">
                    <div className="col-12">
                        <Card header={header}>
                            <form onSubmit={form.handleSubmit(onSubmitTask)} className="error_msg">
                                <div className="p-fluid formgrid grid">
                                    <div className='col-12 lg:col-6'>
                                        <div className="field col-12">
                                            <label htmlFor="subTaskName" className="col-6">
                                                Task Name
                                            </label><span className="w-full">
                                                <Controller name="subTaskName" control={form.control}
                                                    rules={{ required: "Task Name is required" }}
                                                    render={({ field, fieldState }) => (
                                                        <InputText readOnly id='subTaskName' value={field.value} onChange={(e) => field.onChange(e.target.value)}
                                                            placeholder="" className={classNames({ "p-invalid": fieldState.error })} />
                                                    )}
                                                />
                                                {getFormErrorMessage("subTaskName")}</span>
                                        </div>
                                        <div className="field col-12">
                                            <label className="col-6" htmlFor="startDate">
                                                Start Date<span className="p-error">*</span>
                                            </label>
                                            <span className="w-full">
                                                <Controller
                                                    name="startDate"
                                                    control={form.control}
                                                    rules={{ required: "Start Date is required." }}
                                                    render={({ field, fieldState }) => (
                                                        <Calendar disabled showIcon value={field.value} dateFormat="dd/mm/yy" className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => {field.onChange(e.target.value); setMaxStartDate(e.target.value); setValue("endDate", "")}} />
                                                    )}
                                                />
                                                {getFormErrorMessage("startDate")}</span>
                                        </div>
                                        <div className="field col-12">
                                            <label className="col-6" htmlFor="endDate">
                                                End Date<span className="p-error">*</span>
                                            </label>
                                            <span className="w-full">
                                                <Controller
                                                    name="endDate"
                                                    control={form.control}
                                                    rules={{ required: "End Date is required." }}
                                                    render={({ field, fieldState }) => (
                                                        <Calendar disabled id="minmax" minDate={maxStartDate} dateFormat="dd/mm/yy" showIcon value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                                    )}
                                                />
                                                {getFormErrorMessage("endDate")}</span>
                                        </div>
                                        <div className="field col-12">
                                            <label htmlFor="subTaskStatus" className="col-6">
                                                Status
                                            </label><span className="w-full">
                                                <Controller
                                                    name="subTaskStatus"
                                                    control={form.control}
                                                    render={({ field, fieldState }) => (
                                                <Dropdown disabled options={statusList} id='subTaskStatus' value={field.value} />
                                            )}
                                            />
                                            {getFormErrorMessage("subTaskStatus")}</span>
                                        </div>
                                    </div>
                                    <div className='col-12 lg:col-6'>
                                        <div className="field col-12 align-items-start">
                                            <label htmlFor="subTaskDescription" className="col-6 mt-3">
                                                Task Description
                                            </label><span className="w-full">
                                                <Controller name="subTaskDescription" control={form.control}
                                                    rules={{ required: "Description is required" }}
                                                    render={({ field, fieldState }) => (
                                                        <InputTextarea readOnly rows={5} id='subTaskDescription' value={field.value} onChange={(e) => field.onChange(e.target.value)}
                                                            placeholder="" className={classNames({ "p-invalid": fieldState.error })} />
                                                    )}
                                                />
                                                {getFormErrorMessage("subTaskDescription")}</span>
                                        </div>
                                        <div className="field col-12">
                                            <label htmlFor="assignTo" className="col-6">
                                                Assign To
                                            </label><span className="w-full">
                                                <Controller name="assignTo" control={form.control}
                                                    rules={{ required: "Assign To is required" }}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown disabled filter id='assignTo' value={field.value} onChange={(e) => field.onChange(e.target.value)}
                                                            options={userList} optionLabel="first_name" optionValue='_id' placeholder="" className={classNames({ "p-invalid": fieldState.error })} />
                                                    )}
                                                />
                                                {getFormErrorMessage("assignTo")}</span>
                                        </div>
                                       
                                        <div className="field col-12">
                                            <label htmlFor="subTaskRemarks" className="col-6">
                                                Remarks
                                            </label><span className="w-full">
                                                <Controller name="subTaskRemarks" control={form.control}
                                                    // rules={{ required: "Remarks is required" }}
                                                    render={({ field, fieldState }) => (
                                                        <InputText disabled id='subTaskRemarks' value={field.value} onChange={(e) => field.onChange(e.target.value)}
                                                            placeholder="" className={classNames({ "p-invalid": fieldState.error })} />
                                                    )}
                                                />
                                                {getFormErrorMessage("subTaskRemarks")}</span>
                                        </div>
                                        
                                    </div>
                                    <div className='field col-12 lg:col-6'>
                                            <label className="col-6">File Viewer</label>
                                            <span className="w-full">
                                                <Controller name="fileViewer" control={form.control}
                                                    // rules={{ required: "Assign To is required" }}
                                                    render={({ field, fieldState }) => (
                                                        <MultiSelect disabled filter multiselect id='fileViewer' value={field.value} onChange={(e) => field.onChange(e.target.value)}
                                                            options={userList} optionLabel="first_name" optionValue='_id' placeholder="" className={classNames({ "p-invalid": fieldState.error })} />
                                                    )}
                                                />
                                                {getFormErrorMessage("fileViewer")}</span>
                                        </div>
                                        {/* <div className="field col-12 lg:col-6">
                                            <label htmlFor="subTaskDepartment" className="col-6">
                                                Department
                                            </label><span className="w-full">
                                                <Controller name="subTaskDepartment" control={form.control}
                                                    render={({ field, fieldState }) => (
                                                        <InputText readOnly id='subTaskDepartment' value={field.value} 
                                                            placeholder="" />
                                                    )}
                                                />
                                                {getFormErrorMessage("subTaskDepartment")}</span>
                                        </div> */}
                                        {subTask?._id?.fileAttachment ? 
                                      <div className='col-12'>
                                            <div className='px-2 block w-full'>
                                                {subTask?.uploadAttachment?.map((item, index) => {
                                                    return (
                                                        <div className='my-3 px-2 block w-full'>
                                                            <a disabled href={item?.location} target='_blank' style={{fontSize:'13px', color:'#03030A'}}>{item?.filename}</a>
                                                            {/* <i className='pi pi-trash' onClick={() => confirm(index, projectViewDatas)}></i> */}
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                           
                                    </div>
                                    : null }
                                </div>
                            </form>
                            <div className="title1 cursor-pointer mt-2">
                                <span className="PS-title pt-2">Activity</span>
                            </div>
                            <hr className="hr-line"></hr>
                            <div className="flex justify-content-between master-tab-inner">
                                <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                            </div>
                            {/* {activeIndex === 0 && <div></div>} */}
                            {activeIndex === 0 && <div><CommentsHod tocomments={props} /></div>}
                            {/* {activeIndex === 1 && <div></div>} */}
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TaskViewHod