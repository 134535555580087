import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Toast } from 'primereact/toast';
import constants from '../../../constants/constants';
import axios from 'axios';
import 'react-circular-progressbar/dist/styles.css';
import { Divider } from 'primereact/divider';
import { ProgressBar } from 'primereact/progressbar';
import { Chart } from 'primereact/chart';
import getHeaders from '../../../controllers/utils';
import { Carousel } from 'primereact/carousel';
import { Dropdown } from "primereact/dropdown";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import { Button } from "primereact/button";
import Icon from '../../../images/icon.svg';
import Icon1 from '../../../images/icon (1).svg';
import Group from '../../../images/Group 1000001026.svg';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useHistory } from 'react-router-dom';

const DashboardProjects = () => {
    const toast = useRef(null);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState();
    const [projectsData, setProjectsData] = useState();
    const [clientList, setClientList] = useState();
    const [userList, setUserList] = useState();
    const [CN, setCN] = useState();
    const [PL, setPL] = useState();
    const [PM, setPM] = useState();
    const [PO, setPO] = useState();
    const setFilterProjects = useStoreActions((actions) => actions.projectModel.setFilterProjects);
    const filterProjects = useStoreState((actions) => actions.projectModel.filterProjects);

    useEffect(() => {
        getDashboardData();
        getDashboardAllProjectData();
        getClientData();
        getUserList();
        setFilterProjects(null);
    }, []);

    const defaultValues = { clientName: "", productionLocation: "", projectManager: "", projectObject: ""};
    const form = useForm({ defaultValues });
    const { reset, watch } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
      };

      const getClientData = () => {
        setIsLoading(true);
        axios
            .get(constants.APQP_UPL.CLIENT_REGISTRY + "?page=1&limit=1000&sort_by=-created_at", { headers: getHeaders() })
            .then((resp) => {
                setClientList(resp?.data?.data?.map((item)=> {
                    return {
                      _id: item._id, customerName: item.customerName,
                    }
                  }));
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getUserList = () => {
        setIsLoading(true);
        axios
          .get(constants.URL.USERS + "?page=1&limit=1000&sort_by=-created_at", { headers: getHeaders() })
          .then((resp) => {
            setUserList(resp?.data?.data);
          })
          .catch((e) => console.error(e))
          .finally(() => {
            setIsLoading(false);
          });
      }

      const productionLocation = ["India", "France", "Tunisia", "Macedonia", "Mexico", "China"];
      const projectObject = ["Creation", "Modification", "Duplication", "Transfer"];

      const getDashboardData = () => {
        setIsLoading(true);
        axios
            .get(constants.APQP_UPL.PROJECTS_OVERALL_COUNT + "?" + `projectManager=&productionLocation=&client_id=&projectObject=`, { headers: getHeaders() })
            .then((resp) => {
                setRecords(resp?.data?.data);
                reset();
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    };

      const onSubmit = (data) => {
        const { projectManager, productionLocation, clientName, projectObject } = data;
        setIsLoading(true);
        axios
            .get(constants.APQP_UPL.PROJECTS_OVERALL_COUNT + "?" + `projectManager=${(projectManager)}&productionLocation=${(productionLocation)}&client_id=${(clientName)}&projectObject=${(projectObject)}`, { headers: getHeaders() })
            .then((resp) => {
                setRecords(resp?.data?.data);
                setCN(clientName);
                setPL(productionLocation);
                setPO(projectObject);
                setPM(projectManager);
                // reset();
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getDashboardAllProjectData = () => {
        setIsLoading(true);
        axios
            .get(constants.APQP_UPL.PROJECTS_OVERALL_DASHBOARD, { headers: getHeaders() })
            .then((resp) => {
                setProjectsData(resp?.data?.data?.allProjectsDashboard);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const formatDate = (value) => {
        var date = value ? new Date(value) : null;
        return date?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    }

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const labels = projectsData?.map(item => item.projectName);
        const datas = projectsData?.map(item => item.completedPercentage);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'Completed Percentage',
                    data: datas,
                    fill: false,
                    borderDash: [5, 5],
                    tension: 0.4,
                    borderColor: '#B4B4B4',
                    backgroundColor: ['#FEED53','#A1A5FB', '#B5E0F8', '#BEF34F', '#FFC998',], 
                    pointBorderColor: ['#FEED53','#A1A5FB','#B5E0F8','#BEF34F','#FFC998', ], 
                    pointBackgroundColor: ['#FEED53','#A1A5FB','#B5E0F8','#BEF34F','#FFC998', ],
                    borderWidth: 2,
                },
            ],
        };
        
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    position: 'bottom', // Display legend at the bottom
                    labels: {
                        color: textColor
                    },
                    display: false,
                },
            },
            scales: {
                x: {
                    grid: {
                        drawOnChartArea: false,
                    }
                },
                y: {
                    beginAtZero: true,
                    grid: {
                        drawOnChartArea: false,
                    }
                },
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [projectsData]); // Add projectsData as a dependency to useEffect

    const productTemplate = (item) => {
        return (
            <div className='m-3'>
                <div className='dashboard_projects_status-cards p-4'>
                    <h2 className='projects_overview_task_completion_title'>{item?.projectName}</h2>
                    <div className='projects_overview_task_completion_value mb-0 flex justify-content-between'>
                        <span>Task Done</span>
                        <span>{item?.completedPercentage}%</span>
                    </div>
                    <ProgressBar className="my-2" showValue={false} value={item?.completedPercentage} style={{ height: '6px' }} pt={{ value: { style: { background: '#26C10D' } } }}></ProgressBar>
                    <div className='projects_overview_task_completion_value mb-0 flex justify-content-between pt-1'>
                        <span>End Date: {formatDate(item?.projectEndDate)}</span>
                        <span className='dpst-value'>{item?.completedCount}/{item?.sumOfSubTasksCount}</span>
                    </div>
                </div>
            </div>
        );
    };

    const handleFilterProject = (value)=>{
        const data = {
            cardValue: value, projectManager: PM, productionLocation: PL, clientName: CN, projectObject: PO
        }
        setFilterProjects(data);
        history.replace("/app/projects-projects");  
    }

    const handleClear = () => {
        setCN();
        setPL();
        setPO();
        setPM();
    }

    return (
        <>
            <Toast ref={toast} />
            <h3 className="PS-title m-0">Dashboard</h3>
            <Divider />
            <div className='flex'>
            <div className='col-12 md:col-6 lg:col-6'>
                    <div className='grid'>
                        <div className='col-12'>
                            <div className="card">
                                <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
                                    <div className='flex col-12 flex-container'>
                                        <div className="w-full">
                                            <span className="w-full">
                                                <Controller
                                                    name="clientName"
                                                    control={form.control}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown filter options={clientList} optionLabel="customerName" optionValue="_id" value={field.value || ""} placeholder="Customer" onChange={(e) => { field.onChange(e.value); }} style={{width:'100%'}}/>
                                                    )}
                                                />
                                            </span>
                                        </div>
                                        <div className="w-full">
                                            <span className="w-full">
                                                <Controller
                                                    name="projectManager"
                                                    control={form.control}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown filter value={field.value || ""} onChange={(e) => field.onChange(e.target.value)}
                                                            options={userList} optionLabel="first_name" optionValue='first_name' placeholder="Project Manager" style={{width:'100%'}}/>
                                                    )}
                                                />
                                               </span>
                                        </div>
                                        <div className="w-full">
                                            <span className="w-full">
                                                <Controller
                                                    name="productionLocation"
                                                    control={form.control}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown id='productionLocation' options={productionLocation} value={field.value || ""} placeholder="Production Location"
                                                         onChange={(e) => field.onChange(e.target.value)} style={{width:'100%'}}/>
                                                    )}
                                                />
                                                </span>
                                        </div>
                                    </div>
                                    <div className='col-12 flex justify-content-between Pro align-items-center'>
                                        <div className="w-full col-md-4">
                                            <span className="w-full">
                                                <Controller
                                                    name="projectObject"
                                                    control={form.control}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown options={projectObject} value={field.value || ""} placeholder="Object"
                                                         onChange={(e) => field.onChange(e.target.value)} style={{width:'100%'}}/>
                                                    )}
                                                />
                                                </span>
                                        </div>
                                        <div className='project_search col-8 flex justify-content-end'>
                                            <Button className="btn-black mx-2" label="Clear" onClick={() => {form.reset({ clientName: "", projectManager: "", productionLocation: "", projectObject: "" },{ keepErrors: false, });getDashboardData(); handleClear(); }}/>
                                            <Button size="small" loading={isLoading} label="Search" className="btn-yellow" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
            <div className='grid'>
                <div className='col-12'>
                    <div className='mt-5 grid'>
                        <div className='col-12 md:col-6 lg:col-4'>
                            <div onClick={()=> handleFilterProject("")} className='dashboard_projects_status-cards text-center justify-content-between'>
                                <div className='p-4'>
                                <h2 className='dash_pro_stats_value dpsc_blue mt-4'>{records?.totalProject}</h2>
                                <h5 className='dash_pro_stats_title mb-0'>Total Projects</h5>
                                </div>
                                <div className='icon mt-5'>
                                <img src={Icon} alt='icon' />
                                </div>
                            </div>
                        </div>
                        <div className='col-12 md:col-6 lg:col-4'>
                            <div onClick={()=> handleFilterProject("In-Progress")} className='dashboard_projects_status-cards dpsc_yellow text-center'>
                            <div className='p-4'>
                                <h2 className='dash_pro_stats_value mt-4'>{records?.onGoingProjects}</h2>
                                <h5 className='dash_pro_stats_title mb-0'>On Going Projects</h5>
                            </div>
                                <div className='icon mt-5'>
                                <img src={Icon1} alt='icon1' />
                                </div>
                            </div>
                        </div>
                        <div className='col-12 md:col-6 lg:col-4'>
                            <div onClick={()=> handleFilterProject("Completed")} className='dashboard_projects_status-cards dpsc_green text-center'>
                            <div className='p-4'>
                                <h2 className='dash_pro_stats_value mt-4'>{records?.completedProjects}</h2>
                                <h5 className='dash_pro_stats_title mb-0'>Completed Projects</h5>
                            </div>
                                <div className='icon mt-5'>
                                <img src={Group} alt='group' />
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* <div className='my-3 mx-1 card'>
                        <h3 className="PS-title m-0">Project Overview</h3>
                        <div className='my-3 grid'>
                            {projectsData && projectsData.length > 3 ?
                                <Carousel value={projectsData} numVisible={3} numScroll={3} itemTemplate={productTemplate} className='w-full' />
                                :
                                <>{projectsData && projectsData?.map((item) => {
                                    return (
                                        <div className='col-12 md:col-6 lg:col-4'>
                                            <div className='dashboard_projects_status-cards p-4'>
                                                <h2 className='projects_overview_task_completion_title'>{item?.projectName}</h2>
                                                <div className='projects_overview_task_completion_value mb-0 flex justify-content-between'>
                                                    <span>Task Done</span>
                                                    <span>{item?.completedPercentage}%</span>
                                                </div>
                                                <ProgressBar className="my-2" showValue={false} value={item?.completedPercentage} style={{ height: '6px' }} pt={{ value: { style: { background: '#26C10D' } } }}></ProgressBar>
                                                <div className='projects_overview_task_completion_value mb-0 flex justify-content-between pt-1'>
                                                    <span>End Date: {formatDate(item?.projectEndDate)}</span>
                                                    <span className='dpst-value'>{item?.completedCount}/{item?.sumOfSubTasksCount}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                </>
                            }
                        </div>
                    </div> */}
                </div>
            </div>
            </div>

            <div className='col-12 md:col-6 lg:col-6'>
            <div className='grid'>
                <div className='col-12'>
                    <div className="card">
                        <h3 className="PS-title m-0">Project Completion</h3>
                        <Divider />
                        <div className='flex justify-content-center'>
                            <Chart type="line" data={chartData} options={chartOptions} className="w-full" />
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </>
    );
}

export default DashboardProjects;