import React, { useRef }  from 'react';
import { useHistory } from 'react-router-dom';
import logo from './images/image001.png';
import profile from './images/profile-logo.svg';
import { Menu } from 'primereact/menu';
import { useStoreActions } from 'easy-peasy';

export const AppTopbar = (props) => {
    const menu = useRef(null);
    let history = useHistory();
    const setLogOut = useStoreActions((actions) => actions.loginModel.setLogOut);

    let items = [
        {
            label: "Logout",
            icon: 'pi pi-fw pi-power-off',
            command: () => {
                history.replace("/");
                setLogOut(null)
            },
        },
    ];

    return (
        <div className="layout-topbar">
        <Menu model={items} popup ref={menu} />
            <span className='layout-menu-button'><img src={logo} className='surface-900' alt='logo' /></span>
            <button type="button" className="p-link layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </button>
            <div className="logo-link ms-sm-5 hover_cursor flex align-items-center" onClick={(e) => menu.current.toggle(e)}>
                <img className="rightLogo" height="40" width="40" src={profile} />
            </div>

            {/* <button type="button" style={{ visibility: "hidden" }} className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
            </button> */}
        </div>
    );
}
