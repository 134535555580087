import { action, persist} from "easy-peasy";


const userSIPModel = persist({
  evaluationViewDatas: null,
  traineeUserTabDatas: null,
  testQuestionData: null,
  testQuestionScreen: false,
  feedbackBtnVisible: null,
  effectivenessViewScreen: false,

  setEvaluationViewDatas: action((state, payload) => {
    state.evaluationViewDatas = payload;
  }),

  setTraineeUserTabDatas: action((state, payload) => {
    state.traineeUserTabDatas = payload;
  }),
  
  setTestQuestionData: action((state, payload) => {
    state.testQuestionData = payload;
  }),

  setTestQuestionScreen: action((state, payload) => {
    state.testQuestionScreen = payload;
  }),

  setFeedbackBtnVisible: action((state, payload) => {
    state.feedbackBtnVisible = payload;
  }),

  setEffectivenessViewScreen: action((state, payload) => {
    state.effectivenessViewScreen = payload;
  }),
  
});

export default userSIPModel;