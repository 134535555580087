import { action, persist} from "easy-peasy";


const tabModel = {
  activeIndex: 0,
  traineesList: null,
  gameScoreStatus: null,
  detailedGameScoreStatus: null,
  isAuthenticated: false,
  user: null,
  projectViewDatas: null,
  projectDashboardData: null,
  employeeEditValue: null,
  addQuestion: null,
  path: null,

  setUser: action((state, payload) => {
    localStorage.setItem("names", JSON.stringify(payload));
    var storedNames = JSON.parse(localStorage.getItem("names"));
    state.user = storedNames;
  }),

  setActiveIndex: action((state, payload) => {
    state.activeIndex = payload;
  }),

  setPath: action((state, payload) => {
    state.path = payload;
  }),

  setProjectViewDatas: action((state, payload) => {
    state.projectViewDatas = payload;
  }),

  setProjectDashboardData: action((state, payload) => {
    state.projectDashboardData = payload;
  }),
  
  setEmployeeEditValue: action((state, payload) => {
    console.log(payload);
    state.employeeEditValue = payload;
  }),

  setAddQuestion: action((state, payload) => {
    state.addQuestion = payload;
  }),
  
};

export default tabModel;