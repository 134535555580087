import React, { useEffect } from "react";
import { TabMenu } from 'primereact/tabmenu';
import { useStoreActions, useStoreState } from "easy-peasy";
import EmployeeAll from './EmployeeAll';
import EmployeeAdd from './EmployeeAdd';
import DepartmentAdd from "./DepartmentAdd";
import { Divider } from 'primereact/divider';

const Master = () => {
    const setActiveIndex = useStoreActions((actions) => actions.tabModel.setActiveIndex);
    const activeIndex = useStoreState((actions) => actions.tabModel.activeIndex);
    const setFilterProjects = useStoreActions((actions) => actions.projectModel.setFilterProjects);

    const items = [
        { label: 'All Employee' },
        { label: 'Add Employee' },
        { label: 'Department' },
    ];

    useEffect(() => {
        setActiveIndex(0);
        setFilterProjects(null);
    }, []);

    return (
        <>
            <h3 className="PS-title m-0">Master</h3>
            <Divider />
            <div className="Trainee-wrapper">
                <div className="flex justify-content-between master-tab-inner">
                    <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                </div>
                {activeIndex === 0 && <EmployeeAll />}
                {activeIndex === 1 && <EmployeeAdd />}
                {activeIndex === 2 && <DepartmentAdd />}
            </div>
        </>
    )
}
export default Master;