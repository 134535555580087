import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const GanttChart = (props) => {
  const history = useHistory();
  const [tasks, setTasks] = useState();
  const [monthList, setMonthList] = useState();
  const [searchText, setSearchText] = useState('');
  const filterProjects = useStoreState((actions) => actions.projectModel.filterProjects);
  const subTaskProjectName = useStoreState((actions) => actions.projectModel.subTaskProjectName);
  const setSubTaskProjectName = useStoreActions((actions) => actions.projectModel.setSubTaskProjectName);
  const subTaskRedirect = useStoreState((actions) => actions.projectModel.subTaskRedirect);
  const setSubTaskRedirect = useStoreActions((actions) => actions.projectModel.setSubTaskRedirect);
  const fromDashboard = useStoreState((actions) => actions.projectModel.fromDashboard);
  const setFromDashboard = useStoreActions((actions) => actions.projectModel.setFromDashboard);

  const formatDate = (value) => {
    var date = value ? new Date(value) : null;
    return date?.toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  }
  useEffect(() => {
    // setSubTaskRedirect(null);
    // setSubTaskProjectName(null);
  },[]);


  // Generate month data
  const generateMonthData = (start, end) => {
    const months = [];
    const current = new Date(start.getFullYear(), start.getMonth(), 1);
    while (current <= end) {
      months.push(current.toLocaleString("en-US", { month: "long", year: "numeric" }));
      current.setMonth(current.getMonth() + 1);
    }
    return months;
  };

  useEffect(() => {
    if (props && props?.toGantt?.length > 0) {
      const taskItem = props?.toGantt?.filter((item) => {
        const projectManagerMatch = filterProjects?.PM ? item?.projectManager === filterProjects?.PM : true;
        const productionLocationNameMatch = filterProjects?.PL ? item?.productionLocation === filterProjects?.PL : true;
        const clientNameMatch = filterProjects?.CN ? item?.client_id?._id === filterProjects?.CN : true;
        const projectObjectNameMatch = filterProjects?.PO ? item?.projectObject === filterProjects?.PO : true;
        const projectName = item?.projectName?.toLowerCase()?.includes(searchText?.toLowerCase());
        const projectEndDateMatch = filterProjects?.YEAR ? item?.projectEndDate?.includes(filterProjects?.YEAR?.getFullYear()) : true;

        return projectManagerMatch && productionLocationNameMatch && projectObjectNameMatch && clientNameMatch && projectName && projectEndDateMatch
      })?.flatMap(item => ({
        projectName: item.projectName,
        list: item.taskHeader_id.map(taskHeader => ({
          headerTitle: taskHeader.headerTitle,
          startDate: taskHeader.subTasks.reduce((minDate, subTask) => {
            if (!subTask.startDate) return minDate; // Skip if no startDate
            const subTaskDate = new Date(subTask.startDate);
            return minDate ? (subTaskDate < new Date(minDate) ? subTask.startDate : minDate) : subTask.startDate;
          }, null),
          endDate: taskHeader.subTasks.reduce((maxDate, subTask) => {
            if (!subTask.endDate) return maxDate; // Skip if no startDate
            const subTaskDate = new Date(subTask.endDate);
            return maxDate ? (subTaskDate > new Date(maxDate) ? subTask.endDate : maxDate) : subTask.endDate;
          }, null),
          status: taskHeader?.subTasks?.every((item) => item.subTaskStatus === "Open")
            ? "Open"
            : taskHeader?.subTasks?.every((item) => item.subTaskStatus === "Completed")
              ? "Completed"
              : "In-Progress"
        }))
      }));

      // Collect all valid dates from the task items
      const allDates = taskItem.flatMap(project =>
        project.list.flatMap(task => task.startDate && task.endDate && [
          new Date(task.startDate),
          new Date(task.endDate)
        ]
        )).filter((item) => item);

      const minDate = new Date(Math.min(...allDates.map(date => date.getTime())));
      const maxDate = new Date(Math.max(...allDates.map(date => date.getTime())));

      const monthData = generateMonthData(minDate, maxDate);
      setMonthList(monthData);
      setTasks(taskItem);
    }
  }, [props, searchText]);

  const statusBodyTemplate = (status) => {
    let statusStyle;
    switch (status) {
      case "Completed":
        statusStyle = { background: '#26C10D' };
        break;
      case "In-Progress":
        statusStyle = { background: '#F3E900' };
        break;
      case "Open":
        statusStyle = { background: '#CE0303', border: 0 }
        break;
      default:
        statusStyle = { background: 'transparent' };
    }

    return <div style={statusStyle} className='h-full'>{""}</div>;
  };

  // Helper function to format date to "MMM YYYY"
  const formatDateToMonth = (date) => {
    return date ? new Date(date).toLocaleString("en-US", { month: "long", year: "numeric" }) : '';
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Completed":
        return { argb: "26C10D" }; // Green
      case "In-Progress":
        return { argb: "F3E900" }; // Yellow
      case "Open":
        return { argb: "CE0303" }; // Red
      default:
        return { argb: "FFFFFF" }; // Transparent/White
    }
  };

  const handleDownloadGantt = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Gantt Chart");
  
    // Define column headers
    const headers = [
      { header: "Project Name", key: "subtaskName", width: 40 },
      { header: "Start Date", key: "startDate", width: 15 },
      { header: "End Date", key: "endDate", width: 15 },
      ...monthList.map((month) => ({ header: month, key: month, width: 15 })),
    ];
    worksheet.columns = headers;
    
    // Apply font size to header row
    const headerRow = worksheet.getRow(1);  // Get the first row (header row)
    headerRow.font = { size: 14 };  // Set the font size to 14 (adjust as needed)
    
    // Optionally, you can also apply other font properties (e.g., bold, color)
    headerRow.font = {
      size: 12,  // Font size
      bold: true,  // Make the header bold
    };

    // Set light grey background color for the header row
    headerRow.fill = {
      type: 'pattern',  // Use pattern fill
      pattern: 'solid',  // Solid fill
      fgColor: { argb: 'D3D3D3' }  // Light grey color (RGB code for light grey)
    };
  
    // Add project and subtask rows
    tasks.forEach((task) => {
      // Add a row for the project
      const projectRowIndex = worksheet.lastRow ? worksheet.lastRow.number + 1 : 1;
      worksheet.addRow({ subtaskName: task.projectName });
      worksheet.mergeCells(`A${projectRowIndex}:E${projectRowIndex}`); // Merge cells from A to E (or more based on columns)
      const projectRow = worksheet.getRow(projectRowIndex);
      projectRow.getCell(1).font = { bold: true }; // Make project name bold
      projectRow.getCell(1).alignment = { vertical: "middle", horizontal: "left" };
  
      // Add rows for subtasks
      task.list.forEach((subItem) => {
        const row = worksheet.addRow({
          subtaskName: subItem.headerTitle,
          startDate: formatDateToMonth(subItem.startDate),
          endDate: formatDateToMonth(subItem.endDate),
        });
        const borderColor = subItem?.status === "Completed" ? "26C10D" :
                            subItem?.status === "In-Progress" ? "F3E900" : "CE0303";
        row.getCell(1).border = {
          left: { style: "double", color: { argb: borderColor } },
        };
  
        // Highlight the date range columns based on the status
        const startIndex = monthList.findIndex(
          (month) => month === formatDateToMonth(subItem.startDate)
        );
        const endIndex = monthList.findIndex(
          (month) => month === formatDateToMonth(subItem.endDate)
        );
  
        monthList.forEach((_, index) => {
          if (index >= startIndex && index <= endIndex) {
            const cell = row.getCell(4 + index); // Adjust for offset of first 3 columns
            cell.value = ""; // No text
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: getStatusColor(subItem.status), // Add background color
            };
          }
        });
      });
    });
  
    // Save the workbook
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(blob, "GanttChart.xlsx");
  };

  const [selectedProject, setSelectedProject] = useState(null);
  return (
    <>
      <div className='gantt-sticky'>
        <div className='flex justify-content-between align-items-center'>
          <div className='task_progress mt-5'>Project Progress</div>
          <div className='flex gap-2 align-items-center'>
            <InputText value={searchText} type='search' onChange={(e) => setSearchText(e.target.value)} placeholder='Search by Project Name' />
            <Button onClick={handleDownloadGantt} label='Download' className='btn-black' />
          </div>
        </div>
        <hr className='hr-line'></hr>
        <div className='flex justify-content-end align-items-center pb-3'>
          <div className='monthly_delayed_open'></div><span className='status'>Open</span>
          <div className='monthly_inprogress'></div><span className='status'>Inprogress</span>
          <div className='monthly_completed'></div><span className='status'>Completed</span>
        </div>
      </div>
      <div className="w-full overflow-auto gantt-table-wrapper" style={{ height: "calc(100vh - 270px)" }}>
      <table className="gantt-table">
        <thead className="gantt-table-sticky">
          <tr>
            <th className="h-full py-3 gt_task_name user-attachment gantt-project text-left" style={{ minWidth: "200px", zIndex: 4 }}>
              Project Name
            </th>
            <th className="h-full py-3 user-attachment gantt-start text-left" style={{ minWidth: "110px", zIndex: 4 }}>
              Start Date
            </th>
            <th className="h-full py-3 user-attachment gantt-end text-left" style={{ minWidth: "110px", zIndex: 4 }}>
              End Date
            </th>
            {monthList?.map((month, index) => (
              <th key={index} className="h-full px-1 py-3 text-center user-attachment" style={{ borderRight: "1px solid #dfe1e5", minWidth: "125px" }}>
                {month}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tasks?.map((item, i) => {
            const isSelected = selectedProject === item.projectName;

            return (
              <React.Fragment key={i}>
                <tr style={{outline: "1px solid #EAEAEA"}}>
                  <span
                    className="tr-project-name block sticky left-0"
                    onClick={() => setSelectedProject(isSelected ? null : item.projectName)}
                    style={{ cursor: "pointer", color: isSelected ? "#03030A" : "#212121" }}
                  >
                    {item.projectName}
                  </span>
                </tr>

                {isSelected &&
                  item?.list?.map((subItem, i) => {
                    const startIndex = monthList.findIndex(
                      (month) => month === formatDateToMonth(subItem.startDate)
                    );
                    const endIndex = monthList.findIndex(
                      (month) => month === formatDateToMonth(subItem.endDate)
                    );

                    return (
                      <tr
                        key={subItem.id}
                        style={{ cursor: "auto", padding: 10, border: "1px solid #dfe1e5", height: "38.58px" }}
                        className="gantt-value"
                      >
                        <td
                          style={{ maxWidth: "200px" }}
                          onClick={() => {
                            setSubTaskRedirect(subItem.headerTitle);
                            setSubTaskProjectName(item.projectName);
                            setFromDashboard("1");
                            history.replace("/app/projects-projects");
                          }}
                          className="gt_task_name file-upload-size gantt-project px-0 py-1"
                          title={subItem.status}
                        >
                          <span
                            style={{
                              borderLeft: "6px solid",
                              borderLeftColor:
                                subItem?.status === "Completed"
                                  ? "#26C10D"
                                  : subItem?.status === "In-Progress"
                                  ? "#F3E900"
                                  : "#CE0303",
                              fontSize: "xx-large",
                              verticalAlign: "bottom",
                            }}
                            className="mr-1"
                          ></span>
                          {subItem.headerTitle}
                        </td>
                        <td style={{ maxWidth: "110px" }} className="file-upload-size gantt-start">
                          {formatDateToMonth(subItem.startDate)}
                        </td>
                        <td style={{ maxWidth: "110px" }} className="file-upload-size gantt-end">
                          {formatDateToMonth(subItem.endDate)}
                        </td>
                        {monthList?.map((month, index) => (
                          <td
                            key={`${subItem.id}-${index}`}
                            className="month-cell p-0"
                            style={{
                              minWidth: "125px",
                              height: "38.58px",
                              background: index >= startIndex && index <= endIndex ? "#EAEAEA" : "transparent",
                              border: index >= startIndex && index <= endIndex ? 0 : "1px solid #EAEAEA",
                            }}
                          >
                            {index >= startIndex && index <= endIndex ? statusBodyTemplate(subItem.status) : ""}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
    </>
  );
}

export default GanttChart;





