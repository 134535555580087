import React, { useState, useEffect, useRef } from "react";
import constants from "../../../constants/constants";
import "../../../assets/css/Project.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from 'primereact/card';
import { Controller, useForm } from "react-hook-form";
import { Toast } from "primereact/toast";
import classNames from "classnames";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from "primereact/dropdown";
import { useStoreState, useStoreActions} from "easy-peasy";
import axiosInstance from "../../../constants/axiosInstance";
import { FileUpload } from "primereact/fileupload";
import axios from "axios";
import Logout from "../../../images/LogoutVertical.svg";

const AddProjects = ({sendDataToParent}) => {
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clientList, setClientList] = useState();
  const [projectList, setProjectList] = useState();
  const [userList, setUserList] = useState();
  const user = useStoreState((actions) => actions.loginModel.user); 
  const projectEditValue = useStoreState((actions) => actions.projectModel.projectEditValue);
  const setProjectEditValue = useStoreActions((actions) => actions.projectModel.setProjectEditValue);
  const setAddProjects = useStoreActions((actions) => actions.projectModel.setAddProjects);
  const [uploadFile, setUploadFile] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    getClientData();
    getProjectData();
    getUserList();
}, []);

const defaultValues = { projectName: "", startOfProduction: "", yearlyVolume: "", modelNumber: "", startDate: "", clientName: "", modelName: "", productionLocation: "", endDate: "", projectManager: "", salesMan: "", ppapDate: "", ppapByFILECDate: "", projectObject: "", projectRiskAssessment: "", projectNumber: "", weeklyCapacity:"", productDesignation: "", ppapEndDate: "", projectComments:"", productPicture:"" };
const form = useForm({ defaultValues });
const { reset, setValue } = form;
const errors = form.formState.errors;
const getFormErrorMessage = (name) => {
  return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
};

useEffect(() => {
  if (projectEditValue) {
    setValue("projectName", projectEditValue?.projectName)
          setValue("startOfProduction", new Date(projectEditValue?.startOfProduction))
          setValue("modelNumber", projectEditValue?.modelNumber)
          setValue("clientName", projectEditValue?.client_id?._id)
          setValue("modelName", projectEditValue?.modelName)
          setValue("productionLocation", projectEditValue?.productionLocation)
          setValue("projectManager", projectEditValue?.projectManager)
          setValue("salesMan", projectEditValue?.salesMan)
          setValue("ppapDate", new Date(projectEditValue?.ppapDate))
          setValue("ppapByFILECDate", new Date(projectEditValue?.ppapByFILECDate))
          setValue("projectObject", projectEditValue?.projectObject)
          setValue("projectNumber", projectEditValue?.projectNumber) 
          setValue("projectRiskAssessment", projectEditValue?.projectRiskAssessment)
          setValue("yearlyVolume", projectEditValue?.yearlyVolume)
          setValue("weeklyCapacity", projectEditValue?.weeklyCapacity)
          setValue("productDesignation", projectEditValue?.productDesignation)
          setValue("ppapEndDate", new Date(projectEditValue?.ppapEndDate))
          setValue("projectComments", projectEditValue?.projectComments)
          setUploadFile(projectEditValue?.productPicture);
  }
},[projectEditValue]);

  const onSubmit = (data) => {
    const payload = {
      projectName: data.projectName,
      startOfProduction: data.startOfProduction,
      modelNumber: data.modelNumber,
      client_id: data.clientName,
      modelName: data.modelName,
      productionLocation: data.productionLocation,
      projectCreatedBy: user?.user?._id,
      projectManager: data.projectManager,
      salesMan: data.salesMan,
      ppapDate: data.ppapDate,
      ppapByFILECDate: data.ppapByFILECDate,
      projectObject: data.projectObject,
      projectNumber: data.projectNumber,
      projectRiskAssessment: data.projectRiskAssessment,
      yearlyVolume: data.yearlyVolume,
      weeklyCapacity: data.weeklyCapacity,
      productDesignation: data.productDesignation,
      ppapEndDate: data.ppapEndDate,
      projectComments: data.projectComments,
      productPicture: uploadFile && typeof uploadFile === "string" ? uploadFile : "",
    }
    console.log(payload);
    
    setIsLoading(true);
    if(projectEditValue){
      axiosInstance.patch(constants.APQP_UPL.PROJECTS + "/" + projectEditValue?._id, payload)
      .then((resp) => {
        console.log(resp);
        toast.current.show({ severity: "success", summary: "Success", detail: "Project Updated Successfully" });
        reset();
        setAddProjects(false);
        setProjectEditValue(null);
        sendDataToParent("Call"); 
      }).catch((e) => {
        toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
        console.error(e);
      }).finally(() => {
        setIsLoading(false);
      })
    }else {
        axiosInstance.post(constants.APQP_UPL.PROJECTS, payload)
          .then((resp) => {
            // console.log(resp?.data?.data);
            toast.current.show({ severity: "success", summary: "Success", detail: "Added Successfully" });
            reset();
            setAddProjects(false);
            sendDataToParent("Call"); 
          }).catch((e) => {
            toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
            console.error(e);
          }).finally(() => {
            setIsLoading(false);
          })
    }

  };
    
const getClientData = () => {
    setIsLoading(true);
    axiosInstance
        .get(constants.APQP_UPL.CLIENT_REGISTRY + "?page=1&limit=1000&sort_by=-created_at")
        .then((resp) => {
            setClientList(resp?.data?.data?.map((item)=> {
              return {
                _id: item._id, customerName: item.customerName,
              }
            }));
        })
        .catch((e) => console.error(e))
        .finally(() => {
            setIsLoading(false);
        });
}

const getUserList = () => {
  setIsLoading(true);
  axiosInstance
    .get(constants.URL.USERS + "?page=1&limit=1000&sort_by=-created_at")
    .then((resp) => {
      setUserList(resp?.data?.data);
    })
    .catch((e) => console.error(e))
    .finally(() => {
      setIsLoading(false);
    });
}

const getProjectData = () => {
  setIsLoading(true);
  axiosInstance
      .get(constants.APQP_UPL.PROJECTS + "?page=1&limit=1000&sort_by=-created_at")
      .then((resp) => {
          setProjectList(resp?.data?.data);
      })
      .catch((e) => console.error(e))
      .finally(() => {
          setIsLoading(false);
      });
}


  const header = (
    <div className="head-1">
       {!projectEditValue ?
      <h2 className="head-2">Add Project</h2>
      :
      <h2 className="head-2">Edit Project</h2>
       }
    </div>
  );

  const productionLocation = ["India", "France", "Tunisia", "Macedonia", "Mexico", "China"];

  const projectObject = ["Creation", "Modification", "Duplication", "Transfer"];

  const projectRiskAssessment = [
    { label: "Green", value: "Green" },
    { label: "Yellow", value: "Yellow" },
    { label: "Orange", value: "Orange" },
    { label: "Red", value: "Red" },
  ];

    const itemTemplate = (option) => (
      <div style={{ display: "flex", alignItems: "center" }} className="w-full">
        <span className="w-full p-1"
          style={{
            display: "block",
            height: "18px",
            backgroundColor: option.value,
          }}
        ></span>
      </div>
    );

    const colorMap = {
      Green: "green",
      Yellow: "yellow",
      Orange: "orange",
      Red: "red",
    };

    const colorAssessment = {
      Green: "green",
      Yellow: "yellow",
      Orange: "orange",
      Red: "red",
    };

    const handleFileUpload = (e, ids) => {
      const formData = new FormData();
      const files = e.target.files;

      for (let i = 0; i < files.length; i++) {
          formData.append('pdfFiles', files[i]);
      }

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              "x-access-token": localStorage.getItem('access-token'),
              "x-access-time": new Date().toISOString()
          },
      };
      setIsLoading(true);
      axios.post(constants.APQP_UPL.UPLOAD_ATTACHMENTS, formData, config)
          .then((resp) => {
              const uploadedPDF = resp?.data?.data[0]?.location
              setUploadFile(uploadedPDF);
              // toast.current.show({ severity: "success", summary: "Success", detail: "Status Updated Successfully" });
          }).catch((e) => {
              toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
              console.error(e);
          }).finally(() => {
              setIsLoading(false);
          })
  }

console.log(uploadFile);

  return (
    <div>
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12">
          <Card header={header}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
              <div className="p-fluid formgrid grid lr-gap">
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6 fsv-label" htmlFor="projectName">
                  Project Name<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="projectName"
                      control={form.control}
                      rules={{ required: "Project Name is required." }}
                      render={({ field, fieldState }) => (
                        <InputText filter id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                      )}
                    />
                    {getFormErrorMessage("projectName")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6 fsv-label" htmlFor="productDesignation">
                  Product Designation<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                  <Controller
                      name="productDesignation"
                      control={form.control}
                      rules={{ required: "Product Designation is required." }}
                      render={({ field, fieldState }) => (
                        <InputText filter id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                      )}
                    />
                    {getFormErrorMessage("productDesignation")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6 fsv-label" htmlFor="projectNumber">
                  Project Number<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="projectNumber"
                      control={form.control}
                      rules={{ required: "Project Number is required." }}
                      render={({ field, fieldState }) => (
                        <InputText filter id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                      )}
                    />
                    {getFormErrorMessage("projectNumber")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6 fsv-label" htmlFor="projectObject">
                 Object<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="projectObject"
                      control={form.control}
                      rules={{ required: "Object is required" }}
                      render={({ field, fieldState }) => (
                        <Dropdown options={projectObject}  value={field.value} placeholder=""
                        className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                      )}
                    />
                    {getFormErrorMessage("projectObject")}</span>
                </div>
                
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6 fsv-label" htmlFor="clientName">
                  Customer Name<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="clientName"
                      control={form.control}
                      rules={{ required: "Client Name is required." }}
                      render={({ field, fieldState }) => (
                        <Dropdown filter options={clientList} optionLabel="customerName" optionValue="_id" value={field.value} placeholder="" className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => {field.onChange(e.target.value)}} />
                      )}
                    />
                    {getFormErrorMessage("clientName")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6 fsv-label" htmlFor="salesMan">
                  Salesman<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="salesMan"
                      control={form.control}
                      rules={{ required: "Salesman is required." }}
                      render={({ field, fieldState }) => (
                        // <Dropdown filter options={clientList} optionLabel="customerName" optionValue="_id" id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => {field.onChange(e.target.value); setCustomerName(e.target.value)}} />
                        <Dropdown filter  value={field.value} onChange={(e) => field.onChange(e.target.value)}
                        options={userList} optionLabel="first_name" optionValue='first_name' placeholder="" className={classNames({ "p-invalid": fieldState.error })} />
                      )}
                    />
                    {getFormErrorMessage("salesMan")}</span>
                </div>
               
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6 fsv-label" htmlFor="projectManager">
                  Project manager<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="projectManager"
                      control={form.control}
                      rules={{ required: "Project Manager is required." }}
                      render={({ field, fieldState }) => (
                        // <Dropdown filter options={clientList} optionLabel="customerName" optionValue="_id" id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => {field.onChange(e.target.value); setCustomerName(e.target.value)}} />
                        <Dropdown filter value={field.value} onChange={(e) => field.onChange(e.target.value)}
                        options={userList} optionLabel="first_name" optionValue='first_name' placeholder="" className={classNames({ "p-invalid": fieldState.error })} />
                      )}
                    />
                    {getFormErrorMessage("projectManager")}</span>
                </div> 
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6 fsv-label" htmlFor="productionLocation">
                 Production Location<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="productionLocation"
                      control={form.control}
                      rules={{ required: "Location is required" }}
                      render={({ field, fieldState }) => (
                        <Dropdown id='productionLocation'  options={productionLocation}  value={field.value} placeholder=""
                        className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                      )}
                    />
                    {getFormErrorMessage("productionLocation")}</span>
                </div>
                
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6 fsv-label" htmlFor="ppapDate">
                  PPAP Presentation Date<span className="p-error"></span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="ppapDate"
                      control={form.control}
                      // rules={{ required: "PPAP presentation Date is required." }}
                      render={({ field, fieldState }) => (
                        <Calendar showIcon id={field.name} dateFormat="dd/mm/yy" value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)}/>
                        // <Calendar showIcon minDate={minDate} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                      )}
                    />
                    {getFormErrorMessage("ppapDate")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6 fsv-label" htmlFor="startOfProduction">
                  Start of Production(SOP)<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="startOfProduction"
                      control={form.control}
                      rules={{ required: "Start of Production(SOP) is required." }}
                      render={({ field, fieldState }) => (
                        <Calendar id={field.name} dateFormat="dd/mm/yy" value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} showIcon />
                      )}
                    />
                    {getFormErrorMessage("startOfProduction")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6 fsv-label" htmlFor="ppapByFILECDate">
                  PPAP Presentation By FILEC Date<span className="p-error"></span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="ppapByFILECDate"
                      control={form.control}
                      // rules={{ required: "PPAP presentation confirmed by FILEC Date is required." }}
                      render={({ field, fieldState }) => (
                        <Calendar showIcon id={field.name} dateFormat="dd/mm/yy" value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                      )}
                    />
                    {getFormErrorMessage("ppapByFILECDate")}</span>
                </div>
                
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6 fsv-label" htmlFor="yearlyVolume">
                  Yearly Volume<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="yearlyVolume"
                      control={form.control}
                      rules={{ required: "Yearly Volume is required" ,
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Yearly Volume must be a number",
                      },}}
                      render={({ field, fieldState }) => (
                        <InputText  id={field.name} value={field.value}   onChange={(e) => {
                          const numericValue = e.target.value.replace(/\D/g, ''); // Allow only digits
                          field.onChange(numericValue);
                        }}
                        className={classNames({ "p-invalid": fieldState.error })} />
                      )}
                    />
                    {getFormErrorMessage("yearlyVolume")}</span>
                </div>
               
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6 fsv-label" htmlFor="weeklyCapacity">
                  Weekly Capacity<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                  <Controller
                      name="weeklyCapacity"
                      control={form.control}
                      rules={{ required: "Weekly Capacity is required." }}
                      render={({ field, fieldState }) => (
                        <InputText filter id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                      )}
                    />
                    {getFormErrorMessage("weeklyCapacity")}</span>
                </div>
                
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6 fsv-label" htmlFor="projectRiskAssessment">
                Project Risk Assessment<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="projectRiskAssessment"
                      control={form.control}
                      rules={{ required: "Project Risk Assessment is required" }}
                      render={({ field, fieldState }) => (
                        <Dropdown id='projectRiskAssessment'  options={projectRiskAssessment}  value={field.value} itemTemplate={itemTemplate}
                        className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)}
                        style = {{
                          background: colorMap[field.value] || "" ,textIndent: "-9999px"
                         } } />
                      )}
                    />
                    {getFormErrorMessage("projectRiskAssessment")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="projectComments">
                  Comments<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="projectComments"
                      control={form.control}
                      rules={{ required: "Project Comments is required" }}
                      render={({ field, fieldState }) => (
                        <InputText id={field.name}  value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                      )}
                    />
                    {getFormErrorMessage("projectComments")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="beamAngle">
                    Product Picture
                  </label>
                  <span className="w-full">
                    <div className="img-main" onClick={() => inputRef.current.click()} >
                      <div className="prod-img-upld">
                        <div className="inner-img">
                          <div className="img-logo">
                            { uploadFile && typeof uploadFile === "string" ?  (
                                <img src={uploadFile} style={{ width: "24px", height: "24px" }} ></img>
                            ) : (
                            <img src={Logout}  style={{ width: "24px", height: "24px" }} />
                            )}
                            <input className="inbox" onChange={(e) => handleFileUpload(e)} placeholder="Enter Document Name" accept=".png, .jpg, .jpeg" ref={inputRef} type="file" style={{ display: "none" }} />
                          </div>
                          {uploadFile && uploadFile?.length > 0 ? (
                            <div className="text-center">
                              <div className="browse_image mt-1">
                                Browse image to Replace Upload
                              </div>
                              <div className="mt-1 project_upload_image">Maximum: 20MB Format: .png, .jpg,.jpeg</div>
                            </div>
                          ) : (
                            <div className="text-center">
                              <div className="browse_image mt-1">
                                Browse image to upload
                              </div>
                              <div className="mt-1 project_upload_image">Maximum: 20MB  Format: .png, .jpg,.jpeg</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
             
                <div className="flex justify-content-end px-4">
                    <Button icon="pi pi-check" size="small" loading={isLoading} label="Save" className="btn-yellow"  />
                </div>
            </form>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default AddProjects;
