import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useForm } from "react-hook-form";
import React, { useState, useRef, useEffect } from "react";
import profile from "../images/image001.png";
import constants from "../constants/constants";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { useStoreActions } from "easy-peasy";

const Login = () => {
  const history = useHistory();
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const setUser = useStoreActions((actions) => actions.loginModel.setUser);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
  };

  useEffect(() => {
    setUser(null);
  }, []);

  const goto = (to) => {
    history.replace(to);
  };

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    const payload = {
      username: data.userName,
      password: data.password
    }
    setIsLoading(true);
    axios.post(constants.URL.LOGIN, payload)
      .then((resp) => {
        // console.log(resp?.data?.data);
        setUser(resp?.data?.data);
        // if(resp.data.data?.user?.role[0] == "admin"){
          goto("/app/defaultnav");
        // }else {
        //     toast.current.show({ severity: "error", summary: "Failure", detail: "You dont have access" });
        // }
      }).catch((e) => {
        toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
        console.error(e);
      }).finally(() => {
        setIsLoading(false);
      })
  }

  return (
    <div>
      <Toast ref={toast} />

      <div className="login_container">
        <div className="md:col-6 hidden md:flex p-0" style={{ height: "100%" }}>
            <div class="ls-admin_bg"><div className="ls-yellow_bg">
              <div className="flex justify-content-end flex-column p-5 mx-2 w-full h-full ls-text">
                {/* <span className="mb-0 ls-text">ADMIN</span> */}
                <span className="mb-0 ls-subtext">LOGIN</span>
                </div>
              </div>
            </div>
        </div>

        <div className="col-12 md:col-6 flex align-items-center justify-content-center">
          <div className="w-full lg:w-6">
            <div className="mb-5 pb-4">
              <img src={profile} alt="profile" className="" />
            </div>
            <div className="text-left mb-5">
              <div className="head text-3xl mb-3">LOGIN</div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="error_msg">
              <div>
                <div className="">
                  <label htmlFor="userName" className="block text-900  mb-2">User Name</label>
                  <InputText id="userName" type="text" className="w-full mb-3" defaultValue={""}
                    {...register("userName", {
                      required: true,
                    })}
                  />
                  {errors?.userName?.type === "required" && <p className="p-error">This field is required</p>}
                </div>
                <div className="">
                  <label htmlFor="password" className="block text-900 mb-2">Password</label>
                  <div className="relative">
                    <InputText id="password" type={showPassword ? "text" : "password"} className="w-full mb-3"
                      defaultValue={""}
                      {...register("password", {
                        required: "Password is required"
                      })}
                    />
                    <span className="absolute eye-icon-position cursor-pointer" onClick={togglePasswordVisibility}>
                      {showPassword ? (
                        <i className="pi pi-eye-slash" style={{ color: '#708090', fontSize: "16px" }}></i>
                      ) : (
                        <i className="pi pi-eye" style={{ color: '#708090', fontSize: "16px" }}></i>
                      )}
                    </span>
                  </div>
                  {errors?.password && <p>{errors.password.message}</p>}
                                </div>
                {/* <div className="l-forget-text text-right mb-4">Forget Password?</div> */}
                {/* <div className="login_btn"> */}
                  <Button label="Login" disabled={isLoading} className="w-full my-3 login-btn" />
                {/* </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
