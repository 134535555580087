import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useForm } from "react-hook-form";
import React, { useState, useRef, useEffect } from "react";
import profile from "../images/image001.png";
import constants from "../constants/constants";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { useStoreActions,useStoreState } from "easy-peasy";
import Logo from '../images/Logo (2).png';
import Username from '../images/Logoleft.png';
import backgroundImage from '../images/logonBackglight1.jpg';

const Login = () => {
  const history = useHistory();
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const setUser = useStoreActions((actions) => actions.loginModel.setUser);
  const [showPassword, setShowPassword] = useState(false);
  const setLoginDetails = useStoreActions((actions) => actions.projectModel.setLoginDetails);
  const loginDetails = useStoreState((actions) => actions.projectModel.loginDetails);

  const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
  };

  useEffect(() => {
    setUser(null);
    localStorage.removeItem('access-token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('isSessionExpired');
  }, []);

  const goto = (to) => {
    history.replace(to);
  };

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    const payload = {
      username: data.userName,
      password: data.password
    }
    setIsLoading(true);
    axios.post(constants.URL.LOGIN, payload)
      .then((resp) => {
        setUser(resp?.data?.data);
        console.log(resp);
        localStorage.setItem('access-token', resp?.data?.data?.access_token);
        localStorage.setItem('refreshToken', resp?.data?.data?.refresh_token);
        setLoginDetails(resp?.data?.data?.user?._id);
        goto("/app/defaultnav");
      }).catch((e) => {
        toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
        console.error(e);
      }).finally(() => {
        setIsLoading(false);
      })
  }

  return (
    <div>
      <Toast ref={toast} />

      <div className="login_container">
      <div className="col-12 md:col-6 flex justify-content-center align-items-center text-align-center">
        <div className="w-full justify-content-center align-items-center text-align-center flex">
          <div className="w-full lg:w-6">
            <div className="">
              <img src={Username} alt="profile" className="" />
            </div>
            <div className="text-left mb-5">
              <div className="head text-3xl mb-3">Welcome Back!</div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="login_error_msg">
              <div>
                <div className="">
                  <label htmlFor="userName" className="block text-800  mb-2">User Name</label>
                  <InputText id="userName" type="text" className="w-full mb-3" defaultValue={""}
                    {...register("userName", {
                      required: true,
                    })}
                    style={{backgroundColor:'#050505', color:'#FFFFFF'}}
                  />
                  {errors?.userName?.type === "required" && <p className="p-error">This field is required</p>}
                </div>
                <div className="">
                  <label htmlFor="password" className="block text-800 mb-2">Password</label>
                  <div className="relative">
                    <InputText id="password" type={showPassword ? "text" : "password"} className="w-full mb-3"
                      defaultValue={""}
                      {...register("password", {
                        required: "Password is required"
                      })}
                      style={{backgroundColor:'#050505', color:'#FFFFFF'}}
                    />
                    <span className="absolute eye-icon-position cursor-pointer" onClick={togglePasswordVisibility}>
                      {showPassword ? (
                        <i className="pi pi-eye-slash" style={{ color: '#708090', fontSize: "16px" }}></i>
                      ) : (
                        <i className="pi pi-eye" style={{ color: '#708090', fontSize: "16px" }}></i>
                      )}
                    </span>
                  </div>
                  {errors?.password && <p>{errors.password.message}</p>}
                                </div>
                <div className="l-forget-text text-right mb-4" onClick={() => history.push('/email-verification')} style={{cursor:'pointer'}}>Forgot Password?</div>
                {/* <div className="login_btn"> */}
                  <Button label="Login" disabled={isLoading} className="w-full my-3 login-btn"
                    style={{backgroundColor: "#E6FB2D", color: "#323232",transition: "none",}}/>
                {/* </div> */}
              </div>
            </form>
          </div>
        </div>
        </div>

        <div className="md:col-6 hidden md:flex p-3" style={{ height: "100%" }}>
            <div class="ls-admin_bg flex justify-content-center align-items-center text-align-center">
              <div className="flex justify-content-center align-items-center text-align-center">
                {/* <span><img src={Logo} alt="logo" className="" /></span> */}
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
