import React, { useState, useEffect } from 'react';
import axios from 'axios';
import constants from '../../constants/constants';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import getHeaders from '../../controllers/utils';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { TabMenu } from 'primereact/tabmenu';
import { Calendar } from 'primereact/calendar';
import Comments from './Comments';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { classNames } from 'primereact/utils';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';

const ProjectTask = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [remarks, setRemarks] = useState();
    const [records, setRecords] = useState();
    const statusList = ["In-Progress", "Completed"];
    const [isDisabled, setIsDisabled] = useState(false);
    const user = useStoreState((actions) => actions.loginModel.user);
    const projectViewDatas = useStoreState((actions) => actions.projectModel.projectViewDatas);
    const toast = useRef(null);

    const header = (
        <div className="head-1">
            <h2 className="head-2">Task</h2>
        </div>
    );

    const setActiveIndex = useStoreActions((actions) => actions.tabModel.setActiveIndex);
    const activeIndex = useStoreState((actions) => actions.tabModel.activeIndex);

    const items = [
        // { label: 'All' },
        { label: 'Comment' },
        // { label: 'History' },
    ];

    useEffect(() => {
        setActiveIndex(0);
        getData();
    }, []);

    const getData = () => {
        axios.get(constants.APQP_UPL.PROJECTS + "/" + projectViewDatas?._id, { headers: getHeaders() })
            .then((resp) => {
                // const comments = 
                const filteredSubTasks = [];
                resp?.data?.data?.forEach(project => {
                    project.taskHeader_id.forEach(header => {
                        header.subTasks.forEach(subTask => {
                            if (subTask._id._id === projectViewDatas?.subTaskId?._id) {
                                filteredSubTasks.push(subTask);
                            }
                        });
                    });
                });
                console.log(filteredSubTasks[0]);
                setRecords(filteredSubTasks[0]);
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const defaultValues = { subTaskName: "", subTaskDescription: "", subTaskKeyPointers: "", assignTo: "", fileAttachment: "", startDate: "", endDate: "", subTaskRemarks: "" };
    const form = useForm({ defaultValues });
    const { reset, setValue } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    useEffect(() => {
        if (projectViewDatas) {
            setValue("subTaskName", projectViewDatas?.subTaskName)
            setValue("subTaskDescription", projectViewDatas?.subTaskId?.subTaskDescription)
            setValue("subTaskStatus", projectViewDatas?.subTaskStatus ? projectViewDatas?.subTaskStatus : "Open")
            setValue("startDate", projectViewDatas?.subTaskStartDate ? new Date(projectViewDatas?.subTaskStartDate) : null)
            setValue("endDate", projectViewDatas?.subTaskEndDate ? new Date(projectViewDatas?.subTaskEndDate) : null)
            // setValue("fileAttachment", projectViewDatas?.fileAttachment === true ? "Yes" : "No")
            setValue("subTaskRemarks", projectViewDatas?.subTaskRemarks)
            setIsDisabled(projectViewDatas?.subTaskStatus === "Completed");
        }
    }, [projectViewDatas]);

    const onSubmitTask = (data) => {
        const payload = {
            subTaskStatus: data?.subTaskStatus,
            subTaskRemarks: data?.subTaskRemarks,
            completedDate: data?.subTaskStatus === "Completed" ? new Date() : null
        }
        setIsLoading(true);
        axios.patch(constants.APQP_UPL.PROJECTS + "/" + projectViewDatas?._id + "/taskHeader/" + projectViewDatas?.taskHeaderId + "/subtask/" + projectViewDatas?.subTaskId?._id, payload, { headers: getHeaders() })
            .then((resp) => {
                // console.log(resp?.data?.data);
                toast.current.show({ severity: "success", summary: "Success", detail: "Status Updated Successfully" });
                setIsDisabled(data?.subTaskStatus === "Completed");
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const inputRef = useRef(null);
    const handleFileUpload = (e, ids) => {
        const formData = new FormData();
        const files = e.target.files;

        for (let i = 0; i < files.length; i++) {
            formData.append('pdfFiles', files[i]);
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                "x-access-token": localStorage.getItem("e-portal-access-token"),
                "x-access-time": new Date().toISOString()
            },
        };
        setIsLoading(true);
        axios.post(constants.APQP_UPL.UPLOAD_ATTACHMENTS, formData, config)
            .then((resp) => {
                const uploadedPDF = resp?.data?.data?.map(item => { return { location: item.location, filename: item.originalname } })
                uploadAttachments(uploadedPDF, ids);
                // toast.current.show({ severity: "success", summary: "Success", detail: "Status Updated Successfully" });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const uploadAttachments = (uploadedPDF, ids) => {
        const payload = {
            uploadAttachment: uploadedPDF
        }
        setIsLoading(true);
        axios.patch(constants.APQP_UPL.PROJECTS + "/" + ids?._id + "/taskHeader/" + ids?.taskHeaderId + "/subtask/" + ids?.subTaskId?._id, payload, { headers: getHeaders() })
            .then((resp) => {
                getData();
                toast.current.show({ severity: "success", summary: "Success", detail: "File Uploaded Successfully" });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const deleteAttachments = (index, ids) => {
        setIsLoading(true);
        axios.delete(constants.APQP_UPL.PROJECTS + "/" + ids?._id + "/taskHeaders/" + ids?.taskHeaderId + "/subtasks/" + ids?.subTaskId?._id + "/attachments/" + index, { headers: getHeaders() })
            .then((resp) => {
                getData();
                toast.current.show({ severity: "success", summary: "Confirmed", detail: "Deleted Successfully", life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const confirm = (index, ids) => {
        confirmDialog({
            message: 'Do you want to delete this File?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => deleteAttachments(index, ids),
            // reject,
            acceptClassName: "btn-black",
            rejectClassName: "btn-yellow",
            draggable: false,
            // className: "confirm_dilog",
        });
    };

    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog />
            <div>
                <div className="grid">
                    <div className="col-12">
                        <Card header={header}>
                            <form onSubmit={form.handleSubmit(onSubmitTask)} className="error_msg">
                                <div className="p-fluid formgrid grid">
                                    <div className='col-12 lg:col-6'>
                                        <div className="field col-12">
                                            <label htmlFor="subTaskName" className="col-6">
                                                Task Name
                                            </label><span className="w-full">
                                                <Controller name="subTaskName" control={form.control}
                                                    render={({ field, fieldState }) => (
                                                        <InputText disabled id='subTaskName' value={field.value} />
                                                    )}
                                                /></span>
                                        </div>
                                        <div className="field col-12">
                                            <label className="col-6" htmlFor="startDate">
                                                Start Date<span className="p-error">*</span>
                                            </label>
                                            <span className="w-full">
                                                <Controller name="startDate" control={form.control}
                                                    render={({ field, fieldState }) => (
                                                        <Calendar disabled showIcon id={field.name} value={field.value} />
                                                    )}
                                                /></span>
                                        </div>
                                        <div className="field col-12">
                                            <label className="col-6" htmlFor="endDate">
                                                End Date<span className="p-error">*</span>
                                            </label>
                                            <span className="w-full">
                                                <Controller name="endDate" control={form.control}
                                                    render={({ field, fieldState }) => (
                                                        <Calendar disabled showIcon id={field.name} value={field.value} />
                                                    )}
                                                /></span>
                                        </div>
                                        <div className="field col-12">
                                            <label htmlFor="subTaskStatus" className="col-6">
                                                Status
                                            </label><span className="w-full">
                                                <Controller name="subTaskStatus" control={form.control}
                                                    rules={{ required: "Status is required" }}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown disabled={isDisabled} options={statusList} value={field.value} id='subTaskStatus'
                                                            onChange={(e) => field.onChange(e.target.value)} />
                                                    )}
                                                />
                                                {getFormErrorMessage("subTaskName")}</span>
                                        </div>
                                    </div>
                                    <div className='col-12 lg:col-6'>
                                        <div className="field col-12 align-items-start">
                                            <label htmlFor="subTaskDescription" className="col-6 mt-3">
                                                Task Description
                                            </label><span className="w-full">
                                                <Controller name="subTaskDescription" control={form.control}
                                                    render={({ field, fieldState }) => (
                                                        <InputTextarea disabled readOnly rows={4} id='subTaskDescription' value={field.value} />
                                                    )}
                                                /></span>
                                        </div>
                                        <div className="field col-12">
                                            <label htmlFor="subTaskRemarks" className="col-6">
                                                Remarks
                                            </label><span className="w-full">
                                                <Controller name="subTaskRemarks" control={form.control}
                                                    render={({ field, fieldState }) => (
                                                        <InputText id='subTaskRemarks' value={field.value} onChange={(e) => field.onChange(e.target.value)} />
                                                    )}
                                                />
                                                {getFormErrorMessage("subTaskRemarks")}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-content-end">
                                    <Button icon="pi pi-check" size="small" loading={isLoading} label="Save" className="btn-yellow" />
                                </div>
                            </form>
                            {records && records?._id?.fileAttachment ? 
                            <div className='mb-5'>
                            <Divider />
                            <div className='inline-block mb-3'>
                                <div className='flex align-items-center btn-yellow p-3' onClick={() => inputRef.current.click()}>
                                    <label className="">File Attachments</label>
                                    <span className="">
                                        <input multiple className='inbox' onChange={(e) => handleFileUpload(e, projectViewDatas)} placeholder="Enter Document Name" ref={inputRef} type="file" style={{ display: "none" }} required />
                                        <i className="pi pi-upload ml-3" />
                                    </span>
                                </div>
                            </div>
                            {records && records?.uploadAttachment?.map((item, index) => {
                                return (
                                    <div className='mb-3 px-2'>
                                        <a href={item?.location} target='_blank' className='w-2 inline-block'>{item?.filename}</a>
                                        <i className='pi pi-trash' onClick={() => confirm(index, projectViewDatas)}></i>
                                    </div>
                                )
                            })}
                            </div> : null }
                            <div className="title1 cursor-pointer">
                                <span className="PS-title pt-2">Activity</span>
                            </div>
                            <hr className="hr-line"></hr>
                            <div className="flex justify-content-between master-tab-inner">
                                <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                            </div>
                            {/* {activeIndex === 0 && <div></div>} */}
                            {activeIndex === 0 && <div><Comments /></div>}
                            {/* {activeIndex === 1 && <div></div>} */}
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectTask