import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import { Calendar } from "primereact/calendar";
import axios from "axios";
import constants from "../../constants/constants";
import getHeaders from "../../controllers/utils";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Dropdown } from "primereact/dropdown";

const EmployeeAdd = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [employee, setEmployee] = useState();
    const [departmentList, setDepartmentList] = useState();
    const [designationList, setDesignationList] = useState();
    const [reportingList, setReportingList] = useState();
    const employeeEditValue = useStoreState((actions) => actions.tabModel.employeeEditValue);
    const toast = useRef(null);

    useEffect(() => {
        getUserList();
        getUserById();
        getDepartment();
        getDesignationList();
    }, []);

    const getDepartment = () =>{
        setIsLoading(true);
        axios
            .get(constants.URL.METADATA_DEPARTMENT, { headers: getHeaders() })
            .then((resp) => {
                setDepartmentList(resp?.data[0]?.department);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getDesignationList = () => {
        setIsLoading(true);
        axios
            .get(constants.URL.METADATA_DESIGNATION, { headers: getHeaders() })
            .then((resp) => {
                setDesignationList(resp?.data[0]?.designationName);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getUserById = () => {
        setIsLoading(true);
        axios
            .get(constants.URL.USERS + "/" + employeeEditValue?._id, { headers: getHeaders() })
            .then((resp) => {
                console.log(resp?.data?.data);
                setEmployee(resp?.data?.data);
                const data = resp?.data?.data;
                setValue("fname", data?.first_name)
                setValue("lname", data?.last_name)
                setValue("username", data?.username)
                setValue("password", data?.password)
                setValue("empId", data?.emp_id)
                setValue("mob", data?.info?.communication?.mobile_no)
                setValue("amob", data?.info?.communication?.alt_mobile_no)
                setValue("email", data?.email)
                setValue("address", data?.info?.communication?.aadhaar_address.address_line)
                setValue("city", data?.info?.communication?.aadhaar_address?.city)
                setValue("state", data?.info?.communication?.aadhaar_address?.state)
                setValue("caddress", data?.info?.communication?.current_address.address_line)
                setValue("fathername", data?.info?.personal?.father_name)
                setValue("mothername", data?.info?.personal?.mother_name)
                setValue("dob", data?.info?.personal?.birth_date ? new Date(data?.info?.personal?.birth_date) : null)
                setValue("doj", data?.info?.joining_date ? new Date(data?.info?.joining_date) : null)
                setValue("qualification", data?.info?.education?.qualification)
                setValue("trainingHistory", data?.info?.training_history)
                setValue("aadhar", data?.info?.finance?.aadhaar_no)
                setValue("pan", data?.info?.finance?.pan_no)
                setValue("hod", data?.reporting_to)
                setValue("emp_type", data?.emp_type)
                setValue("department", data?.department)
                setValue("designation", data?.designation)
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getUserList = () => {
        setIsLoading(true);
        axios
            .get(constants.URL.USERS + "?page=1&limit=1000&sort_by=-created_at", { headers: getHeaders() })
            .then((resp) => {
                setReportingList(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const onSubmit = (data) => {
        // console.log(data);
        const payload = {
            joining_date: data?.doj,
            user: {
                first_name: data?.fname,
                last_name: data?.lname,
                email: data?.email,
                username: data?.username,
                password: data?.password,
                emp_id: data?.empId,
                emp_type: data?.emp_type,
                department: data?.department,
                designation: data?.designation,
                reporting_to: data?.hod,
                joining_date: data?.doj,
                role: ["user"],
            },
            info: {
                training_history: data?.trainingHistory,
                joining_date: data?.doj,
                communication: {
                    mobile_no: data?.mob,
                    alt_mobile_no: data?.amob,
                    aadhaar_address: {
                        address_line: data?.address,
                        city: data?.city,
                        state: data?.state
                    },
                    current_address: {
                        address_line: data?.caddress,
                    },
                },
                personal: {
                    birth_date: data?.dob,
                    father_name: data?.fathername,
                    mother_name: data?.mothername
                },
                education: {
                    qualification: data?.qualification,
                    year_passed: data?.passedOut
                },
                finance: {
                    pan_no: data?.pan,
                    aadhaar_no: data?.aadhar,
                    acc_no: data?.acc_no
                }
            }
        }
        
        setIsLoading(true);
        {!employeeEditValue ?
        axios.post(constants.URL.USERS, payload, { headers: getHeaders() })
        .then((resp)=>{
            // console.log(resp);
            toast.current.show({ severity: "success", summary: "Success", detail: "Employee Added Successfully" });
            reset();
        }).catch((e)=>{
            toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
            console.error(e);
        }).finally(()=>{
            setIsLoading(false);
        })
        : 
        axios.put(constants.URL.USERS + "/" + employeeEditValue?._id, payload, { headers: getHeaders() })
        .then((resp)=>{
            // console.log(resp);
            toast.current.show({ severity: "success", summary: "Success", detail: "User updated successfully" });
            reset();
        }).catch((e)=>{
            toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
            console.error(e);
        }).finally(()=>{
            setIsLoading(false);
        })
    }
    };

    const defaultValues = { fname: "", lname: "", username: "", password: "", empId: "", mob: "", amob: "", email: "", address: "", city: "", state: "", caddress: "", dob: "", fathername: "", mothername: "", doj: "", qualification: "", trainingHistory: "", aadhar: "", pan: "", hod: "", emp_type: "", department: "", designation: "" };
    const form = useForm({ defaultValues });
    const { reset, setValue } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    return (
        <>
            <Toast ref={toast} />
            
            <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
                <div className="card p-0">
                    <div className="card-title px-4 py-3">
                        <h4 className="card-heading mb-0">Basic Information</h4>
                    </div>
                    <div className="p-fluid formgrid grid lr-gap p-4">
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="fname">
                                First Name<span className="p-error">*</span>
                            </label>
                            <span className="w-full">
                            <Controller
                                name="fname"
                                control={form.control}
                                rules={{ required: "First Name is required." }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                )}
                            />
                            {getFormErrorMessage("fname")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="lname">
                                Last Name
                            </label><span className="w-full">
                            <Controller
                                name="lname"
                                control={form.control}
                                // rules={{ required: "Last Name is required." }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("lname")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="username">
                                User Name<span className="p-error">*</span>
                            </label>
                            <span className="w-full">
                            <Controller
                                name="username"
                                control={form.control}
                                rules={{ required: "User Name is required." }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                )}
                            />
                            {getFormErrorMessage("username")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="password">
                                Password<span className="p-error">*</span>
                            </label><span className="w-full">
                            <Controller
                                    name="password"
                                    control={form.control}
                                    rules={{
                                        required: !employeeEditValue ? "Password is required" : false,
                                            uppercase: value => /[A-Z]/.test(value) || "Password must contain at least one uppercase letter",
                                            lowercase: value => /[a-z]/.test(value) || "Password must contain at least one lowercase letter",
                                            number: value => /[0-9]/.test(value) || "Password must contain at least one number",
                                            specialCharacter: value => /[!@#$%^&*(),.?":{}|<>]/.test(value) || "Password must contain at least one special character",
                                            minLength: { value: 6, message: "Password must be at least 6 characters long" },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            {...field}
                                            id="password"
                                            type="password"
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.target.value)}
                                            disabled={employeeEditValue} // Disable if needed
                                            className={classNames({ "p-invalid": fieldState?.error })}
                                        />
                                    )}
                                /> 
                            {getFormErrorMessage("password")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="empId">
                                Employee ID<span className="p-error">*</span>
                            </label><span className="w-full">
                            <Controller
                                name="empId"
                                control={form.control}
                                rules={{ required: "Employee ID is required." }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("empId")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="mob">
                                Mobile Number<span className="p-error">*</span>
                            </label><span className="w-full">
                            <Controller
                                name="mob"
                                control={form.control}
                                rules={{ 
                                    required: "Mobile Number is required.",
                                    validate: (value) => {
                                        if (value && value.length < 10) {
                                            return "Mobile Number must be at least 10 digits.";
                                        }
                                        return true;
                                    }
                                 }}
                                render={({ field, fieldState }) => (
                                    <InputText keyfilter={/^[0-9]+$/} maxLength={10} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("mob")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="amob">
                                Alternative Mobile Number
                            </label><span className="w-full">
                            <Controller
                                name="amob"
                                control={form.control}
                                rules={{ 
                                    // required: "Alternative Mobile Number is required.",
                                validate: (value) => {
                                    if (value && value.length < 10) {
                                        return "Mobile Number must be at least 10 digits.";
                                    }
                                    return true;
                                } }}
                                render={({ field, fieldState }) => (
                                    <InputText keyfilter={/^[0-9]+$/} maxLength={10} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("amob")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="email">
                                Email Id<span className="p-error">*</span>
                            </label><span className="w-full">
                            <Controller
                                name="email"
                                control={form.control}
                                rules={{ 
                                    required: "Email Id is required.",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address.",
                                }, }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("email")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="address">
                                Address as per Aadhaar
                            </label><span className="w-full">
                            <Controller
                                name="address"
                                control={form.control}
                                // rules={{ required:  "Address is required." }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("address")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="city">
                                City
                            </label><span className="w-full">
                            <Controller
                                name="city"
                                control={form.control}
                                // rules={{ required:  "City is required." }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("city")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="state">
                                State
                            </label><span className="w-full">
                            <Controller
                                name="state"
                                control={form.control}
                                // rules={{ required:  "State is required." }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("state")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="caddress">
                                Year of Residence in Current Address
                            </label><span className="w-full">
                            <Controller
                                name="caddress"
                                control={form.control}
                                // rules={{ required:  "Current Address is required." }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("caddress")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="dob">
                                Date of Birth
                            </label><span className="w-full">
                            <Controller
                                name="dob"
                                control={form.control}
                                // rules={{ required:  "Date of Birth is required." }}
                                render={({ field, fieldState }) => (
                                    <Calendar id={field.name} dateFormat="dd/mm/yy" value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} showIcon />
                                )}
                            />
                            {getFormErrorMessage("dob")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="fathername">
                                Father Name
                            </label><span className="w-full">
                            <Controller
                                name="fathername"
                                control={form.control}
                                // rules={{ required:  "Father Name is required." }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("fathername")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="mothername">
                                Mother Name
                            </label><span className="w-full">
                            <Controller
                                name="mothername"
                                control={form.control}
                                // rules={{ required:  "Mother Name is required." }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("mothername")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="doj">
                                Date of Joining
                            </label><span className="w-full">
                            <Controller
                                name="doj"
                                control={form.control}
                                // // rules={{ required:  "Date of Joining is required." }}
                                render={({ field, fieldState }) => (
                                    <Calendar id={field.name} dateFormat="dd/mm/yy" value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} showIcon />
                                )}
                            />
                            {getFormErrorMessage("doj")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="qualification">
                            Education Qualification (Highest)
                            </label><span className="w-full">
                            <Controller
                                name="qualification"
                                control={form.control}
                                // // rules={{ required:  "Qualification is required." }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("qualification")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="trainingHistory">
                                Training History
                            </label><span className="w-full">
                            <Controller
                                name="trainingHistory"
                                control={form.control}
                                // // rules={{ required:  "Training History is required." }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("trainingHistory")}</span>
                        </div>
                    </div>
                    <div className="card-title px-4 py-3">
                        <h4 className="card-heading mb-0">Other Information</h4>
                    </div>
                    <div className="p-fluid formgrid grid lr-gap p-4">
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="department">
                                Department<span className="p-error">*</span>
                            </label><span className="w-full">
                            <Controller
                                name="department"
                                control={form.control}
                                rules={{ required: "Department is required." }}
                                render={({ field, fieldState }) => (
                                    <Dropdown options={departmentList} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("department")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="designation">
                                Designation<span className="p-error">*</span>
                            </label><span className="w-full">
                            <Controller
                                name="designation"
                                control={form.control}
                                rules={{ required: "Designation is required." }}
                                render={({ field, fieldState }) => (
                                    <Dropdown options={designationList} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("designation")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="hod">
                            Head of the Department<span className="p-error">*</span>
                            </label><span className="w-full">
                            <Controller
                                name="hod"
                                control={form.control}
                                rules={{ required: "Head of the Department is required." }}
                                render={({ field, fieldState }) => (
                                    <Dropdown options={reportingList} optionLabel="first_name" optionValue="_id" id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("hod")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="emp_type">
                            Employment Type (Consultant / Employee)<span className="p-error">*</span>
                            </label><span className="w-full">
                            <Controller
                                name="emp_type"
                                control={form.control}
                                rules={{ required: "Employment Type is required." }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("emp_type")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6" htmlFor="pan">PAN Number</label><span className="w-full">
                            <Controller
                                name="pan"
                                control={form.control}
                                // rules={{ required: "PAN Number is required." }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            /></span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6 mb-0">
                            <label className="col-6" htmlFor="aadhar">Aadhaar Number
                            </label><span className="w-full">
                            <Controller
                                name="aadhar"
                                control={form.control}
                                // rules={{ required: "Aadhaar Number is required." }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            /></span>
                        </div>
                    </div>
                <div className="flex justify-content-end p-4 pt-0">
                    <Button icon="pi pi-check" size="small" loading={isLoading} label="Save" className="btn-yellow" />
                </div>
                </div>
            </form>
        </>
    );
};
export default EmployeeAdd;
