import React, { useEffect, useState } from "react";
import { TabMenu } from 'primereact/tabmenu';
import { useStoreActions, useStoreState } from "easy-peasy";
import EmployeeAll from './EmployeeAll';
import EmployeeAdd from './EmployeeAdd';
import DepartmentAdd from "./DepartmentAdd";
import Designation from "./Designation";

const Master = () => {
    const setActiveIndex = useStoreActions((actions) => actions.tabModel.setActiveIndex);
    const activeIndex = useStoreState((actions) => actions.tabModel.activeIndex);

    const items = [
        { label: 'All Employee' },
        { label: 'Add Employee' },
        { label: 'Department' },
        { label: 'Designation' },
    ];

    useEffect(() => {
        setActiveIndex(0);
    }, []);

    return (
        <>
            <div className="Trainee-wrapper">
                <div className="flex justify-content-between master-tab-inner">
                    <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                    {/* <Button className="btn" label="Add" /> */}
                </div>
                {activeIndex === 0 && <EmployeeAll />}
                {activeIndex === 1 && <EmployeeAdd />}
                {activeIndex === 2 && <DepartmentAdd />}
                {activeIndex === 3 && <Designation />}
            </div>
            
        </>
    )
}
export default Master;