import React, { useState, useEffect, useRef } from 'react';
import constants from '../../constants/constants';
import "../../assets/css/Project.css"
import { useStoreActions, useStoreState } from 'easy-peasy';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ProjectTask from './ProjectTask';
import { Toast } from 'primereact/toast';
import back from "../../images/back.svg";
import timer from './../../images/timer.png';
import delaytimer from './../../images/delaytimer.png';
import { Divider } from 'primereact/divider';
import axiosInstance from '../../constants/axiosInstance';
import { differenceInBusinessDays } from 'date-fns';
import { Button } from "primereact/button";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';

const ProjectsHOD = () => {
    const toast = useRef(null);
    const [records, setRecords] = useState();
    const [projectName, setProjectName] = useState();
    const [taskName, setTaskName] = useState();
    const [milestone, setMilestone] = useState();
    const [endDate, setEndDate] = useState();
    const [completionDate, setCompletionDate] = useState();
    const [taskStatus, setTaskStatus] = useState();
    const [completionStatus, setCompletionStatus] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [viewScreen, setViewScreen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const user = useStoreState((actions) => actions.loginModel.user);
    const projectViewDatas = useStoreState((actions) => actions.projectModel.projectViewDatas);
    const setProjectViewDatas = useStoreActions((actions) => actions.projectModel.setProjectViewDatas);
    const setUserDashboardFilterProjects = useStoreActions((actions) => actions.projectModel.setUserDashboardFilterProjects);
    const userDashboardFilterProjects = useStoreState((actions) => actions.projectModel.userDashboardFilterProjects);

    useEffect(() => {
        setIsLoading(true);
        getProjects();
    }, []);

    const getProjects = () => {
        axiosInstance.get(constants.APQP_UPL.PROJECT_ASSIGNED_BY_USER_ID + user?.user?._id)
            .then((resp) => {
                const data = resp?.data?.data?.flatMap(record => {
                    return record.taskHeader_id.flatMap(header => {
                        return header.subTasks.map(subTask => {
                            return {
                                ...record,
                                headerTitle: header.headerTitle,
                                subTaskName: subTask.subTaskName,
                                subTaskStatus: subTask.subTaskStatus,
                                subTaskRemarks: subTask.subTaskRemarks,
                                taskHeaderId: header._id,
                                subTaskId: subTask._id,
                                subTaskEndDate: subTask.endDate,
                                subTaskStartDate: subTask.startDate,
                                uploadAttachment: subTask.uploadAttachment,
                                completedDate: subTask.completedDate,
                                completionStatus: subTask.completionStatus,
                                delayDayCount: subTask.delayDayCount,
                                taskCriticalityStatus: header.taskCriticalityStatus,
                            };
                        });
                    });
                })
                setRecords(data);
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        if (userDashboardFilterProjects) {
            const status = userDashboardFilterProjects?.cardValue
            if (status === "Delayed") {
                setCompletionStatus(status);
            } else {
                let s_s = status === "In-Progress" ? ["In-Progress", "Open"] : status === "" ? ["Open", "In-Progress", "Completed"] : [status];
                setTaskStatus(s_s);
            }
        }
    }, [userDashboardFilterProjects]);

    useEffect(() => {
        if (projectName || milestone || taskName || endDate || completionDate || taskStatus || completionStatus) {

        }
    }, [projectName, milestone, taskName, endDate, completionDate, taskStatus, completionStatus]);

    const handleView = (item) => {
        if (item?.projectStatus !== "Hold") {
            setProjectViewDatas(item);
            setViewScreen(true);
            setSelectedRow(item);
        }
    }

    const formatDate = (value) => {
        var date = value ? new Date(value) : null;
        return date?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    }

    const statusBodyTemplate = (status) => {
        let statusStyle;
        switch (status) {
            case 'Completed':
                statusStyle = { color: '#659000', background: '#ECFCC0', display: "inline" };
                break;
            case 'In-Progress':
                statusStyle = { color: '#9E00C6', background: '#F9E1FF', display: "inline" };
                break;
            case "Open":
                statusStyle = { color: '#493BD7', background: '#E2DFFF', display: "inline" };
                break;
            default:
                statusStyle = { color: 'black', background: '' };
        }

        return <div style={statusStyle} className='task-status'>{status ? status : "Open"}</div>;
    };

    const HoldBodyTemplate = (status) => {
        let statusStyle;
        switch (status) {
            case 'Hold':
                statusStyle = { color: '#DF5D40', background: '#FFDBD4', border: "1px solid #FFDBD4", display: "inline" };
                break;
        }

        return <span style={statusStyle} className='task-status'>{status === "Hold" ? status : ""}</span>;
    }

    const headerTemplate = (data) => {
        return (
            <React.Fragment>
                <span className="group-project-name" style={{ marginRight: '10px' }}>{data.projectName}</span>
                <span className="">{HoldBodyTemplate((data?.projectStatus))}</span>
            </React.Fragment>
        );
    };


    const [expandedRows, setExpandedRows] = useState([]);

    const calculateWorkingDaysDelay = (rowData) => {
        const endDate = new Date(rowData.subTaskEndDate)
        const completionDate = new Date(rowData.completedDate);

        if (isNaN(endDate) || isNaN(completionDate)) {
            return 'Invalid Date';
        }

        const delay = differenceInBusinessDays(completionDate, endDate) + 1;
        return delay > 0 ? `${delay} days` : 'No Delay';
    };

    const dateRowFilterTemplate = (options) => {
        return (
            <Calendar
                value={options.value ? new Date(options.value) : null}
                onChange={(e) => options.filterApplyCallback(e.value)}
                dateFormat="dd-mm-yy"
                placeholder="Select a date"
            />
        );
    };

    const handleClear = () => {
        setProjectName();
        setTaskName();
        setMilestone();
        setEndDate();
        setCompletionDate();
        setTaskStatus([]);
        setCompletionStatus();
        setUserDashboardFilterProjects(null);
    }

    const exportExcel = async () => {
        setIsLoading(true);
            try {
                const response = await axiosInstance.get(constants.APQP_UPL.DOWNLOAD_BY_ASSIGNED_TO + "/" + user?.user?._id, {
                    // headers: getHeaders(),
                    responseType: "blob",
                });                  
              const url = URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "Task Dashboard.xlsx");
        
              document.body.appendChild(link);
              link.click();
              URL.revokeObjectURL(url);
            } catch (error) {
              console.error("Error downloading file:", error);
            } finally {
                setIsLoading(false);
            };
    }
    
    return (
        <div>
            <Toast ref={toast} />
            {!viewScreen ?
                <div>
                    <div className='flex justify-content-between align-items-center'>
                        <h3 className="mb-0 user-dashboard-title">Task Register </h3>
                        <Button onClick={exportExcel} label='Download' className='btn-black' />
                    </div>
                    <Divider />
                    <div className="card" style={{ backgroundColor: '#F8F8F8' }}>
                        <form className="error_msg">
                            <div className='flex col-12 flex-container'>
                                <div className="w-full">
                                    <InputText value={projectName} onChange={(e) => setProjectName(e.target.value)} className="w-full" placeholder="Project Name" />
                                </div>
                                <div className="w-full">
                                    <span className="w-full">
                                        <InputText className='dropdown-input' value={taskName} placeholder="Task Name" onChange={(e) => setTaskName(e.target.value)} style={{ width: '100%' }} />
                                    </span>
                                </div>
                                <div className="w-full">
                                    <span className="w-full">
                                        <InputText className='dropdown-input' value={milestone} placeholder="Milestone" onChange={(e) => setMilestone(e.target.value)} style={{ width: '100%' }} />
                                    </span>
                                </div>
                                <div className="w-full">
                                    <span className="w-full">
                                        <Calendar className='dropdown-input' value={endDate} placeholder="End Date" onChange={(e) => setEndDate(e.target.value)} style={{ width: '100%' }} dateFormat="dd-mm-yy" showIcon />
                                    </span>
                                </div>
                                <div className="w-full">
                                    <span className="w-full">
                                        <Calendar className='dropdown-input' value={completionDate} placeholder="Completion Date" onChange={(e) => setCompletionDate(e.target.value)} style={{ width: '100%' }} dateFormat="dd-mm-yy" showIcon />
                                    </span>
                                </div>
                                <div className="w-full">
                                    <MultiSelect value={taskStatus} options={["Open", "In-Progress", "Completed"]} className="w-full" placeholder="Subtask Status" onChange={(e) => setTaskStatus(e.target.value)} />
                                </div>
                                <div className="w-full">
                                    <Dropdown value={completionStatus} options={["On-Time", "Delayed"]} className="w-full" placeholder="Completion Status" onChange={(e) => setCompletionStatus(e.target.value)} />
                                </div>
                                <div className='project_search'>
                                    <Button className="btn-black mt-1" label="Clear" onClick={handleClear} />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="grid mt-3">
                        <div className="col-12">
                            <div className="table-wrapper user-proj">
                                <DataTable loading={isLoading} value={records?.filter((item) => {
                                    const projectNameMatch = projectName ? item?.projectName?.toLowerCase()?.includes(projectName?.toLowerCase()) : true;
                                    const taskNameMatch = taskName ? item?.subTaskName?.toLowerCase()?.includes(taskName?.toLowerCase()) : true;
                                    const milestoneMatch = milestone ? item?.headerTitle?.toLowerCase()?.includes(milestone?.toLowerCase()) : true;
                                    const endDateMatch = endDate ? formatDate(item?.subTaskEndDate) == formatDate(endDate) : true;
                                    const completionDateMatch = completionDate ? formatDate(item?.completedDate) == formatDate(completionDate) : true;
                                    const status = taskStatus && taskStatus?.length > 0 ? taskStatus?.includes(item?.subTaskStatus) : true;
                                    const completion = completionStatus ? item?.completionStatus === completionStatus : true;

                                    return projectNameMatch && taskNameMatch && milestoneMatch && endDateMatch && completionDateMatch && status && completion
                                })} selectionMode="single" selection={selectedRow} onSelectionChange={(e) => handleView(e.value)}
                                    sortOrder={1} sortMode="single" groupRowsBy="projectName" sortField="projectName" rowGroupMode="subheader"
                                    expandableRowGroups expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                                    scrollable rowGroupHeaderTemplate={headerTemplate} rowClassName={rowData => rowData.taskCriticalityStatus === "Hold" ? 'hold-projects-disabled-row' : ''}>
                                    <Column field="subTaskName" header="Task Name" showFilterMenu={false} style={{ minWidth: '250px' }} filter filterPlaceholder="Search by name" className='user-project-header' bodyClassName="user-project-bodyColumn"></Column>
                                    <Column field="headerTitle" header="Milestone" showFilterMenu={false} filter filterPlaceholder="Search by name" className='user-project-header' bodyClassName="user-project-bodyColumn" style={{ minWidth: '200px' }}></Column>
                                    <Column field="subTaskEndDate" header="End Date" filterField="date" dataType="date" showFilterMenu={false} filter filterPlaceholder="Search by name" body={(rowData)=> formatDate(rowData?.subTaskEndDate)} className='user-project-header' bodyClassName="user-project-bodyColumn" style={{ minWidth: '140px' }}></Column>
                                    <Column field="completedDate" header="Completion Date" filterElement={dateRowFilterTemplate} showFilterMenu={false} filter filterPlaceholder="Search by name" body={(rowData)=> formatDate(rowData?.completedDate)} className='user-project-header' bodyClassName="user-project-bodyColumn" style={{ minWidth: '160px' }}></Column>
                                    <Column field="subTaskStatus" header="Subtask Status" showFilterMenu={false} filter filterPlaceholder="Search by name" body={(rowData) => statusBodyTemplate(rowData?.subTaskStatus)} className='user-project-header' bodyClassName="user-project-bodyColumn" style={{ minWidth: '150px' }}></Column>
                                    <Column field="completionStatus" header="Completion Status" showFilterMenu={false} filter filterPlaceholder="Search by name" body={(rowData) => (<span className={rowData?.completionStatus === "Delayed" ? 'delayed' : rowData?.completionStatus === "OnTime" ? 'ontime' : ''}> {rowData?.completionStatus === "Delayed" ? <img src={delaytimer} style={{ paddingRight: '3px', width: '19px', height: '17px' }} /> : rowData?.completionStatus === "OnTime" ? <img src={timer} style={{ paddingRight: '3px', width: '19px', height: '17px' }} /> : null} {rowData?.completionStatus} </span>)} className='user-project-header'></Column>
                                    <Column field="delayDayCount" header="Number of delayed days"  className='user-project-header' bodyClassName="user-project-bodyColumn" style={{ minWidth: '150px' }} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
                : <div>
                    <div className="title1 cursor-pointer mt-2" onClick={() => { setViewScreen(false); setSelectedRow(null); getProjects(); }}>
                        <img src={back} className="back-logo mr-2" />
                        <span className="PS-title pt-2">Task Register / {projectViewDatas?.subTaskName}</span>
                    </div>
                    <hr className="hr-line"></hr>
                    <ProjectTask />
                </div>}
        </div>
    )
}

export default ProjectsHOD