import FeedbackModel from './FeedBackModel'
import loginModel from './loginModel'
import projectModel from './projectModel'
import sipModel from './sipModel'
import tabModel from './tabModel'
import userSIPModel from './userSIPModel'

const models = {
    tabModel: tabModel,
    loginModel: loginModel,
    projectModel: projectModel,
    sipModel: sipModel,
    userSIPModel: userSIPModel,
    FeedbackModel: FeedbackModel
}

export default models