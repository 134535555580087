import { action, persist} from "easy-peasy";


const FeedbackModel = persist({
  feedbackQueueViewScreen: false,

  setFeedbackQueueViewScreen: action((state, payload) => {
    state.feedbackQueueViewScreen = payload;
  }),
  
});

export default FeedbackModel;