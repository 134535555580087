import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import constants from "../../../constants/constants";
import { useStoreActions, useStoreState } from "easy-peasy";
import { InputTextarea } from "primereact/inputtextarea";
import axiosInstance from "../../../constants/axiosInstance";

const AddNewClients = () => {
    const [isLoading, setIsLoading] = useState(false);
    const setActiveIndex = useStoreActions((actions) => actions.tabModel.setActiveIndex);
    const employeeEditValue = useStoreState((actions) => actions.tabModel.employeeEditValue);
    const toast = useRef(null);
    const setFilterProjects = useStoreActions((actions) => actions.projectModel.setFilterProjects);

    useEffect(() => {
    setFilterProjects(null);
    },[]);
    
    useEffect(() => {
        if (employeeEditValue) {
            setValue("customerName", employeeEditValue?.customerName)
            setValue("financialAccount", employeeEditValue?.financialAccount)
            setValue("vatNumber", employeeEditValue?.vatNumber)
            setValue("legalEntity", employeeEditValue?.legalEntity)
            setValue("zipCode", employeeEditValue?.zipCode)
            setValue("city", employeeEditValue?.city)
            setValue("country", employeeEditValue?.country)
            setValue("completeAddress", employeeEditValue?.completeAddress)
            setValue("incoterm", employeeEditValue?.incoterm)
        }
    }, [employeeEditValue]);

    const onSubmit = (data) => {
        // console.log(data);
        const payload = {
                customerName: data.customerName,
                financialAccount: data.financialAccount,
                vatNumber: data.vatNumber,
                legalEntity: data.legalEntity,
                zipCode: data.zipCode,
                city: data.city,
                completeAddress: data.completeAddress,
                country: data.country,
                incoterm: data.incoterm, 
        }

        setIsLoading(true);
        {
            !employeeEditValue ?
            axiosInstance.post(constants.APQP_UPL.CLIENT_REGISTRY, payload)
                .then((resp) => {
                    // console.log(resp);
                    toast.current.show({ severity: "success", summary: "Success", detail: "Client Added Successfully" });
                    reset();
                }).catch((e) => {
                    toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                    console.error(e);
                }).finally(() => {
                    setIsLoading(false);
                })
            :
            axiosInstance.patch(constants.APQP_UPL.CLIENT_REGISTRY + "/" + employeeEditValue?._id, payload)
                .then((resp) => {
                    // console.log(resp);
                    toast.current.show({ severity: "success", summary: "Success", detail: "Client updated successfully" });
                    reset();
                    setActiveIndex(0);
                }).catch((e) => {
                    toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                    console.error(e);
                }).finally(() => {
                    setIsLoading(false);
                })
        }
    };

    const defaultValues = { customerName: "", financialAccount: "", vatNumber: "", legalEntity: "", zipCode: "", city: "", completeAddress: "", country: "", incoterm: ""};
    const form = useForm({ defaultValues });
    const { reset, setValue } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    return (
        <>
            <Toast ref={toast} />
            <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
                <div className="card p-0">
                    <div className="card-title px-4 py-3">
                        <h4 className="card-heading mb-0">New Customer Form</h4>
                    </div>
                    <div className="flex">
                    <div className="p-fluid formgrid grid p-4">
                        <div className="field col-12">
                            <label className="col-6 fsv-label" htmlFor="customerName">
                                Customer Name<span className="p-error">*</span>
                            </label>
                            <span className="w-full">
                                <Controller
                                    name="customerName"
                                    control={form.control}
                                    rules={{ required: "Customer Name is required." }}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("customerName")}</span>
                        </div>
                        <div className="field col-12">
                            <label className="col-6 fsv-label" htmlFor="financialAccount">
                            Financial Account N°<span className="p-error">*</span>
                            </label>
                            <span className="w-full">
                                <Controller
                                    name="financialAccount"
                                    control={form.control}
                                    rules={{ required: "Financial Account N° is required." }}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                    )}
                                />
                                {getFormErrorMessage("financialAccount")}</span>
                        </div>
                        <div className="field col-12">
                            <label className="col-6 fsv-label" htmlFor="legalEntity">
                            Legal Entity<span className="p-error">*</span>
                            </label><span className="w-full">
                                <Controller
                                    name="legalEntity"
                                    control={form.control}
                                    rules={{ required: "Legal Entity is required." }}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("legalEntity")}</span>
                        </div>
                        <div className="field col-12">
                            <label className="col-6 fsv-label" htmlFor="zipCode">
                            ZIP Code<span className="p-error">*</span>
                            </label><span className="w-full">
                                <Controller
                                    name="zipCode"
                                    control={form.control}
                                    rules={{ required:  "ZIP Code is required." }}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("zipCode")}</span>
                        </div>
                        <div className="field col-12">
                            <label className="col-6 fsv-label" htmlFor="city">
                            City<span className="p-error">*</span>
                            </label><span className="w-full">
                                <Controller
                                    name="city"
                                    control={form.control}
                                    rules={{ required:  "City is required." }}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("city")}</span>
                        </div>
                    </div>
                    <div className="p-fluid formgrid grid p-4">
                        <div className="field col-12 align-items-start">
                            <label className="col-6 lg:mt-3 fsv-label" htmlFor="completeAddress">
                                Complete Address<span className="p-error">*</span>
                            </label><span className="w-full">
                                <Controller
                                    name="completeAddress"
                                    control={form.control}
                                    rules={{ required: "Complete Address is required." }}
                                    render={({ field, fieldState }) => (
                                        <InputTextarea rows={5} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("completeAddress")}</span>
                        </div>
                        <div className="field col-12">
                            <label className="col-6 fsv-label" htmlFor="country">
                                Country<span className="p-error">*</span>
                            </label><span className="w-full">
                                <Controller
                                    name="country"
                                    control={form.control}
                                    rules={{required: "Country is required."}}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("country")}</span>
                        </div>
                        <div className="field col-12">
                            <label className="col-6 fsv-label" htmlFor="incoterm">
                            Incoterm<span className="p-error">*</span>
                            </label><span className="w-full">
                                <Controller
                                    name="incoterm"
                                    control={form.control}
                                    rules={{ required:  "Incoterm is required." }}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("incoterm")}</span>
                        </div>
                        <div className="field col-12">
                            <label className="col-6 fsv-label" htmlFor="vatNumber">
                            Code NIF / VAT Number<span className="p-error">*</span>
                            </label><span className="w-full">
                                <Controller
                                    name="vatNumber"
                                    control={form.control}
                                    rules={{ required:  "VAT Number is required." }}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("vatNumber")}</span>
                        </div>
                        
                    </div>
                    </div>
                    <div className="flex justify-content-end p-4 pt-0">
                        <Button icon="pi pi-check" size="small" loading={isLoading} label="Save" className="btn-yellow" />
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddNewClients;
