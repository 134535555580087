import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { AppTopbar } from './AppTopbar';
import { AppMenu } from './AppMenu';
import PrimeReact from 'primereact/api';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   
import 'primeflex/primeflex.css';                                  
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import './assets/layout/layout.scss';
import './App.css';
import './override.css';
import { useStoreActions, useStoreState } from 'easy-peasy';
import CustomRoute from './CustomRoute';
import { Route, useHistory } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Master from './components/admin/Master';
import Projects from './components/admin/projects/Projects';
import ProjectsHOD from './components/hodUser/ProjectsHOD';
import ClientRegistry from './components/admin/projects/ClientRegistry';
import ProjectsDefinition from './components/admin/projects/ProjectsDefinition';
import DashboardProjects from './components/admin/projects/DashboardProjects';
import SessionExpiredModel from './SessionExpiredModel';

const App = () => {
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const [isAuthScreen, setAuthScreen] = useState(true);
    const user = useStoreState((actions) => actions.loginModel.user);
    const setEvaluationViewDatas = useStoreActions((actions) => actions.sipModel.setEvaluationViewDatas);
    const evaluationViewDatas = useStoreState((actions) => actions.sipModel.evaluationViewDatas);
    const setPath = useStoreActions((actions) => actions.tabModel.setPath);
    // console.log(user);

    useEffect(() => {
        setEvaluationViewDatas(null);
    }, []);

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(()=>{
        if(["/login","/register"].includes(location.pathname)){
            // console.log(location.pathname)
            setAuthScreen(true)
            setStaticMenuInactive(true)
        }else{
            // console.log(location.pathname)
            const path_route = (location.pathname == "/app/defaultnav" || location.pathname == "/app/projects" || location.pathname == "/app/employee")
            setPath(path_route)
            setAuthScreen(false)
            setStaticMenuInactive(false)
        }
        // console.log(location.pathname)
    },[location.pathname])

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const menuAPQPAdmin = [
        {
            label: "",
            items: [
                { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/app/defaultnav' },
                { label: 'Projects', icon: 'pi pi-fw pi-file', to: '/app/projects-projects' },
                { label: 'Definition', icon: 'pi pi-fw pi-check-square', to: '/app/projects-definition' },
                { label: 'Client Registry', icon: 'pi pi-fw pi-calendar-plus', to: '/app/projects-client-registry' },
                { label: 'Master', icon: 'pi pi-fw pi-file-edit', to: '/app/employee' },
            ]
        },
    ];

    const menuUser = [
        {
            label: "",
            items: [
                
                { label: 'Dashboard', icon:'pi pi-fw pi-home', to: '/app/defaultnav' },
                { label: 'Projects', icon:'pi pi-fw pi-file', to: '/app/us-projects' },
            ]
        },
    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    // session expired
    const [sessionExpired, setSessionExpired] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const tokenExpiryHandler = () => {
            setSessionExpired(true);
        };

        const tokenExpiryTime = new Date(user?.time).getTime();
        const twelveHoursInMilliseconds = 10 * 3600000;
        const newTokenExpiryTime = tokenExpiryTime + twelveHoursInMilliseconds;
        const currentTime = new Date().getTime();
        const timeUntilExpiry = newTokenExpiryTime - currentTime;
        // console.log(newTokenExpiryTime, currentTime, (newTokenExpiryTime - currentTime), new Date(newTokenExpiryTime));

        if (timeUntilExpiry > 0) {
            setTimeout(tokenExpiryHandler, timeUntilExpiry);
        } else {
            tokenExpiryHandler();
        }
        console.log("Token expiry time:", new Date(newTokenExpiryTime).toLocaleString());

        return () => clearTimeout(tokenExpiryHandler);
    }, []);

    const handleSessionExpired = () => {
        setSessionExpired(false);
        history.push('/');
    };
    console.log(new Date(user?.time));

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            {
               isAuthScreen === false && (
                    <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
               )
            }
            {
               isAuthScreen === false && (
                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={user?.user?.role[0] === "admin" ? menuAPQPAdmin : menuUser} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>)
            }

            <SessionExpiredModel visible={sessionExpired} onHide={handleSessionExpired} />

            <div className="layout-main-container">
                <div className="layout-main">
                    <Route exact path="/app/defaultnav" component={user?.user?.role[0] === "admin" ? DashboardProjects : Dashboard} />
                    {/* <CustomRoute path="/app/projects-dashboard" component={DashboardProjects} 
                        allowedRoles={["admin"]} currentUserRoles={user?.user?.role} /> */}
                    <CustomRoute path="/app/projects-projects" component={Projects} 
                        allowedRoles={["admin"]} currentUserRoles={user?.user?.role} />
                    <CustomRoute path="/app/projects-definition" component={ProjectsDefinition} 
                        allowedRoles={["admin"]} currentUserRoles={user?.user?.role} />
                    <CustomRoute path="/app/projects-client-registry" component={ClientRegistry} 
                        allowedRoles={["admin"]} currentUserRoles={user?.user?.role} />

                    <CustomRoute path="/app/employee" component={Master} 
                        allowedRoles={["admin"]} currentUserRoles={user?.user?.role} />
                    
                    {/* <CustomRoute exact path="/app/defaultnav" component={Dashboard} 
                        allowedRoles={["HOD", "User"]} currentUserRoles={user?.user?.role} /> */}
                    <CustomRoute path="/app/us-projects" component={ProjectsHOD} 
                        allowedRoles={["user"]} currentUserRoles={user?.user?.role} />
                </div>
            </div>

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>

        </div>
    );

}

export default App;
