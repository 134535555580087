import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useStoreActions } from "easy-peasy";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from "primereact/button";
import constants from "../../constants/constants";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import axiosInstance from "../../constants/axiosInstance";

const EmployeeAll = () => {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState();
    const setActiveIndex = useStoreActions((actions) => actions.tabModel.setActiveIndex);
    const setEmployeeEditValue = useStoreActions((actions) => actions.tabModel.setEmployeeEditValue);

    useEffect(() => {
        getUserList();
        setEmployeeEditValue(null);
    }, []);

    const getUserList = () => {
        setIsLoading(true);
        axiosInstance
            .get(constants.URL.USERS + "?page=1&limit=1000&sort_by=-created_at")
            .then((resp) => {
                setRecords(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleTaskDelete = (item) => {
        setIsLoading(true);
        axiosInstance.delete(constants.URL.USERS + "/" + item?._id)
            .then((resp) => {
                // console.log(resp?.data?.data);
                getUserList();
                toast.current.show({ severity: "success", summary: "Confirmed", detail: "Deleted Successfully", life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const confirm = (item) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleTaskDelete(item),
            acceptClassName: "btn-black",
            rejectClassName: "btn-yellow",
            draggable: false,
            // className: "confirm_dilog",
        });
    };

    const IconBodyTemplate = (item) => {
        return (
            <div className='flex justify-content-end gap-2'>
                <Button className="btn-black" label="Edit" onClick={() => {setActiveIndex(1); setEmployeeEditValue(item)}} />
                <Button className="btn-black" label="Delete" onClick={() => confirm(item)} />
            </div>
        );
    };


    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
            <div className="flex align-items-center justify-content-end gap-2 mr-3">
                {/* {records && records.length > 0 ? "Overall count: "+ records.length : ""} */}
            </div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search Employee Name" className="" />
                </span>
            </div>
        );
    };

    const header = renderHeader();

    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="grid">
                <div className="col-12">
                    <div className="table-wrapper">
                        <DataTable value={records} globalFilterFields={['first_name']} filters={filters} header={header} paginator rows={50} loading={isLoading}>
                            <Column field="first_name" header="Employee Name" style={{ textAlign: 'left' }}
                                pt={{ headerCell: { style: { display: 'flex', justifyContent: 'start' } } }} 
                                className='user-project-header' bodyClassName="user-project-bodyColumn"></Column>
                            <Column field="email" header="Email ID" className='user-project-header' bodyClassName="user-project-bodyColumn"></Column>
                            <Column field="department" header="Department" className='user-project-header' bodyClassName="user-project-bodyColumn"></Column>
                            <Column header="" body={IconBodyTemplate} className='user-project-header' bodyClassName="user-project-bodyColumn"></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeAll;
