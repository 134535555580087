import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import constants from "../../constants/constants";
import { useStoreState } from "easy-peasy";
import { Dropdown } from "primereact/dropdown";
import axiosInstance from "../../constants/axiosInstance";
import { MultiSelect } from "primereact/multiselect";

const EmployeeAdd = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [departmentList, setDepartmentList] = useState();
    const [reportingList, setReportingList] = useState();
    const employeeEditValue = useStoreState((actions) => actions.tabModel.employeeEditValue);
    const toast = useRef(null);

    useEffect(() => {
        getUserList();
        getUserById();
        getDepartment();
    }, []);

    const getDepartment = () =>{
        setIsLoading(true);
        axiosInstance
            .get(constants.URL.METADATA_DEPARTMENT)
            .then((resp) => {
                setDepartmentList(resp?.data[0]?.department);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getUserById = () => {
        setIsLoading(true);
        axiosInstance
            .get(constants.URL.USERS + "/" + employeeEditValue?._id)
            .then((resp) => {
                const data = resp?.data?.data;
                setValue("fname", data?.first_name)
                setValue("lname", data?.last_name)
                setValue("username", data?.username)
                setValue("password", data?.password)
                setValue("mob", data?.mobile_no)
                setValue("email", data?.email)
                setValue("hod", data?.reporting_to)
                setValue("department", data?.department)
                setValue("role", data?.role[0])
                setValue("module_access", data?.module_access)
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getUserList = () => {
        setIsLoading(true);
        axiosInstance
            .get(constants.URL.USERS + "?page=1&limit=1000&sort_by=-created_at")
            .then((resp) => {
                setReportingList(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const onSubmit = (data) => {
        // console.log(data);
        const payload = {
            user: {
                first_name: data?.fname,
                last_name: data?.lname,
                email: data?.email,
                username: data?.username,
                password: data?.password,
                emp_id: data?.empId,
                department: data?.department,
                reporting_to: data?.hod,
                mobile_no: data?.mob,
                role: [data?.role],
                module_access: data?.module_access
            },
           
        }
        
        setIsLoading(true);
        {!employeeEditValue ?
        axiosInstance.post(constants.URL.USERS, payload)
        .then((resp)=>{
            // console.log(resp);
            toast.current.show({ severity: "success", summary: "Success", detail: "Employee Added Successfully" });
            reset();
        }).catch((e)=>{
            toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
            console.error(e);
        }).finally(()=>{
            setIsLoading(false);
        })
        : 
        axiosInstance.put(constants.URL.USERS + "/" + employeeEditValue?._id, payload)
        .then((resp)=>{
            // console.log(resp);
            toast.current.show({ severity: "success", summary: "Success", detail: "User updated successfully" });
            reset();
        }).catch((e)=>{
            toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
            console.error(e);
        }).finally(()=>{
            setIsLoading(false);
        })
    }
    };

    const defaultValues = { fname: "", lname: "", username: "", password: "", empId: "", mob: "", email: "", hod: "", emp_type: "", department: "", module_access: [], role: "" };
    const form = useForm({ defaultValues });
    const { reset, setValue, watch } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };
    const access = watch("role")
    const roleList = [{label: "Admin", value: "admin"}, {label: "User", value: "user"}]
    const AdminModuleAccess = ["Project Register", "Tasks Dashboard", "Task Register", "Customer Registry", "Master"];
    const UserModuleAccess = ["Projects Register", "Task Register", "File Viewer"];

    return (
        <>
            <Toast ref={toast} />
            
            <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
                <div className="card p-0">
                    <div className="card-title px-4 py-3">
                        <h4 className="card-heading mb-0">Basic Information</h4>
                    </div>
                    <div className="p-fluid formgrid grid lr-gap p-4">
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6 fsv-label" htmlFor="fname">
                                First Name<span className="p-error">*</span>
                            </label>
                            <span className="w-full">
                            <Controller
                                name="fname"
                                control={form.control}
                                rules={{ required: "First Name is required." }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                )}
                            />
                            {getFormErrorMessage("fname")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6 fsv-label" htmlFor="lname">
                                Last Name
                            </label><span className="w-full">
                            <Controller
                                name="lname"
                                control={form.control}
                                // rules={{ required: "Last Name is required." }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("lname")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6 fsv-label" htmlFor="username">
                                User Name<span className="p-error">*</span>
                            </label>
                            <span className="w-full">
                            <Controller
                                name="username"
                                control={form.control}
                                rules={{ required: "User Name is required." }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("username")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6 fsv-label" htmlFor="password">
                                Password<span className="p-error">*</span>
                            </label><span className="w-full">
                            <Controller
                                    name="password"
                                    control={form.control}
                                    rules={{
                                        required: !employeeEditValue ? "Password is required" : false,
                                            uppercase: value => /[A-Z]/.test(value) || "Password must contain at least one uppercase letter",
                                            lowercase: value => /[a-z]/.test(value) || "Password must contain at least one lowercase letter",
                                            number: value => /[0-9]/.test(value) || "Password must contain at least one number",
                                            specialCharacter: value => /[!@#$%^&*(),.?":{}|<>]/.test(value) || "Password must contain at least one special character",
                                            minLength: { value: 6, message: "Password must be at least 6 characters long" },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            {...field}
                                            id="password"
                                            type="password"
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.target.value)}
                                            disabled={employeeEditValue} // Disable if needed
                                            className={classNames({ "p-invalid": fieldState?.error })}
                                        />
                                    )}
                                /> 
                            {getFormErrorMessage("password")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6 fsv-label" htmlFor="mob">
                                Mobile Number<span className="p-error">*</span>
                            </label><span className="w-full">
                            <Controller
                                name="mob"
                                control={form.control}
                                rules={{ 
                                    required: "Mobile Number is required.",
                                    // validate: (value) => {
                                    //     if (value && value.length < 10) {
                                    //         return "Mobile Number must be at least 10 digits.";
                                    //     }
                                    //     return true;
                                    // }
                                 }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("mob")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6 fsv-label" htmlFor="email">
                                Email Id<span className="p-error">*</span>
                            </label><span className="w-full">
                            <Controller
                                name="email"
                                control={form.control}
                                rules={{ 
                                    required: "Email Id is required.",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address.",
                                }, }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("email")}</span>
                        </div>
                    </div>
                    <div className="card-title px-4 py-3">
                        <h4 className="card-heading mb-0">Other Information</h4>
                    </div>
                    <div className="p-fluid formgrid grid lr-gap p-4">
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6 fsv-label" htmlFor="department">
                                Department<span className="p-error">*</span>
                            </label><span className="w-full">
                            <Controller
                                name="department"
                                control={form.control}
                                rules={{ required: "Department is required." }}
                                render={({ field, fieldState }) => (
                                    <Dropdown options={departmentList} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("department")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6 fsv-label" htmlFor="hod">
                            Head of the Department<span className="p-error">*</span>
                            </label><span className="w-full">
                            <Controller
                                name="hod"
                                control={form.control}
                                rules={{ required: "Head of the Department is required." }}
                                render={({ field, fieldState }) => (
                                    <Dropdown options={reportingList} optionLabel="first_name" optionValue="_id" id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("hod")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6 fsv-label" htmlFor="hod">
                            Role<span className="p-error">*</span>
                            </label><span className="w-full">
                            <Controller
                                name="role"
                                control={form.control}
                                rules={{ required: "Role is required." }}
                                render={({ field, fieldState }) => (
                                    <Dropdown options={roleList} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => {field.onChange(e.value); setValue('module_access', [])}} />
                                )}
                            />
                            {getFormErrorMessage("role")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6 fsv-label" htmlFor="module_access">
                            Module Access<span className="p-error">*</span>
                            </label><span className="col-6">
                            <Controller
                                name="module_access"
                                control={form.control}
                                rules={{ required: "Module Access is required." }}
                                render={({ field, fieldState }) => (
                                    <MultiSelect options={access === "admin" ? AdminModuleAccess : UserModuleAccess} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                )}
                            />
                            {getFormErrorMessage("module_access")}</span>
                        </div>
                    </div>
                <div className="flex justify-content-end p-4 pt-0">
                    <Button icon="pi pi-check" size="small" loading={isLoading} label="Save" className="btn-yellow" />
                </div>
                </div>
            </form>
        </>
    );
};
export default EmployeeAdd;
