import React, { useEffect } from "react";
import { TabMenu } from 'primereact/tabmenu';
import { useStoreActions, useStoreState } from "easy-peasy";
import AddNewClients from "./AddNewClients";
import AllClientsList from "./AllClientsList";

const ClientRegistry = () => {
    const setActiveIndex = useStoreActions((actions) => actions.tabModel.setActiveIndex);
    const activeIndex = useStoreState((actions) => actions.tabModel.activeIndex);

    const items = [
        { label: 'New' },
        { label: 'Clients' },
    ];

    useEffect(() => {
        setActiveIndex(0);
    }, []);

    return (
        <>
            <div className="Trainee-wrapper">
                <div className="flex justify-content-between master-tab-inner">
                    <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                </div>
                {activeIndex === 0 && <AddNewClients />}
                {activeIndex === 1 && <AllClientsList />}
            </div>
            
        </>
    )
}
export default ClientRegistry;