import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import constants from '../../constants/constants';
import "../../assets/css/Project.css"
import { Button } from "primereact/button";
import { useHistory } from 'react-router-dom';
import getHeaders from '../../controllers/utils';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ProjectTask from './ProjectTask';
import { Toast } from 'primereact/toast';
import back from "../../images/back.svg";

const ProjectsHOD = () => {
    const history = useHistory();
    const toast = useRef(null);
    const [records, setRecords] = useState();
    const [value, setValue] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [viewScreen, setViewScreen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const user = useStoreState((actions) => actions.loginModel.user);
    const projectViewDatas = useStoreState((actions) => actions.projectModel.projectViewDatas);
    const setProjectViewDatas = useStoreActions((actions) => actions.projectModel.setProjectViewDatas);

    const goto = (to) => {
        history.replace(to);
    };

    useEffect(() => {
        setIsLoading(true);
        getProjects();
    }, []);

    const getProjects = () => {
        axios.get(constants.APQP_UPL.PROJECT_ASSIGNED_BY_USER_ID + user?.user?._id, { headers: getHeaders() })
            .then((resp) => {
                // console.log(resp?.data?.data);
                setRecords(resp?.data?.data);
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const handleView = (item) => {
        if (item?.projectStatus !== "Hold") {
            setProjectViewDatas(item);
            setViewScreen(true);
            setSelectedRow(item);
        }
    }

    const formatDate = (value) => {
        var date = value?.subTaskEndDate ? new Date(value?.subTaskEndDate) : null;
        return date?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    }

    const formatCompletionDate = (value) => {
        var date = value?.completedDate ? new Date(value?.completedDate) : null;
        return date?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    }

    const statusBodyTemplate = (status) => {
        let statusStyle;
        switch (status) {
            case 'Completed':
                statusStyle = { color: '#659000', background: '#ECFCC0', display: "inline" };
                break;
            case 'In-Progress':
                statusStyle = { color: '#9E00C6', background: '#F9E1FF', display: "inline" };
                break;
            case "Open":
                statusStyle = { color: '#493BD7', background: '#E2DFFF', display: "inline" };
                break;
            default:
                statusStyle = { color: 'black', background: '' };
        }

        return <div style={statusStyle} className='task-status'>{status ? status : "Open"}</div>;
    };

    const HoldBodyTemplate = (status) => {
        let statusStyle;
        switch (status) {
            case 'Hold':
                statusStyle = { color: '#DF5D40', background: '#FFDBD4', border: "1px solid #FFDBD4", display: "inline" };
                break;
        }

        return <div style={statusStyle} className='task-status'>{status === "Hold" ? status : ""}</div>;
    };

    return (
        <div>
            <Toast ref={toast} />
            {!viewScreen ?
                <div>
                    <div className="flex align-items-center justify-content-between mt-2">
                        <h3 className="PS-title pt-2 mb-1">Project</h3>
                    </div>
                    <hr className='hr-line'></hr>
                    <div className="grid mt-3">
                        <div className="col-12">
                            <div className="table-wrapper">
                                <DataTable loading={isLoading} value={records?.flatMap(record => {
                                    // Expand each record to include multiple rows, each corresponding to a subTaskStatus value
                                    return record.taskHeader_id.flatMap(header => {
                                        return header.subTasks.map(subTask => {
                                            return {
                                                ...record,
                                                headerTitle: header.headerTitle,
                                                subTaskName: subTask.subTaskName,
                                                subTaskStatus: subTask.subTaskStatus,
                                                subTaskRemarks: subTask.subTaskRemarks,
                                                taskHeaderId: header._id,
                                                subTaskId: subTask._id,
                                                subTaskEndDate: subTask.endDate,
                                                subTaskStartDate: subTask.startDate,
                                                uploadAttachment: subTask.uploadAttachment,
                                                completedDate: subTask.completedDate,
                                            };
                                        });
                                    });
                                })} selectionMode="single" selection={selectedRow} onSelectionChange={(e) => handleView(e.value)} sortField="subTaskEndDate" sortOrder={1} sortMode="single">
                                    <Column field="subTaskName" header="Task Name"></Column>
                                    <Column field="subTaskEndDate" header="" body={(rowData)=> HoldBodyTemplate(rowData?.projectStatus)}></Column>
                                    <Column field="headerTitle" header="Header"></Column>
                                    <Column field="projectName" header="Project"></Column>
                                    <Column field="subTaskEndDate" header="End Date" body={formatDate}></Column>
                                    <Column field="completedDate" header="Completion Date" body={formatCompletionDate}></Column>
                                    <Column field="subTaskStatus" header="Subtask Status" body={(rowData)=>statusBodyTemplate(rowData?.subTaskStatus)}></Column>
                                    {/* <Column header="" body={IconBodyTemplate}></Column> */}
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
                : <div>
                    <div className="title1 cursor-pointer mt-2" onClick={() => { setViewScreen(false); setSelectedRow(null); getProjects(); }}>
                        <img src={back} className="back-logo mr-2" />
                        <span className="PS-title pt-2">Project / {projectViewDatas?.subTaskName}</span>
                    </div>
                    <hr className="hr-line"></hr>
                    <ProjectTask />
                </div>}
        </div>
    )
}

export default ProjectsHOD