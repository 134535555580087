import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Toast } from 'primereact/toast';
import constants from '../../../constants/constants';
import 'react-circular-progressbar/dist/styles.css';
import { Divider } from 'primereact/divider';
import { Chart } from 'primereact/chart';
import { Dropdown } from "primereact/dropdown";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useHistory } from 'react-router-dom';
import axiosInstance from '../../../constants/axiosInstance';
import GanttChart from './GanttChart';
import classNames from "classnames";
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from "primereact/inputtext";

const DashboardProjects = () => {
    const toast = useRef(null);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState();
    const [histogram, setHistogram] = useState();
    const [filteredProjectsRecords, setFilteredProjectsRecords] = useState();
    const [projectsData, setProjectsData] = useState();
    const [clientList, setClientList] = useState();
    const [userList, setUserList] = useState();
    const [CN, setCN] = useState('');
    const [PL, setPL] = useState('');
    const [PM, setPM] = useState();
    const [PO, setPO] = useState();
    const [YEAR, setYEAR] = useState();
    const setFilterProjects = useStoreActions((actions) => actions.projectModel.setFilterProjects);
    const filterProjects = useStoreState((actions) => actions.projectModel.filterProjects);
    const [projectName, setProjectName] = useState('');
    const [projectStatus, setProjectStatus] = useState('');

    useEffect(() => {
        getDashboardData();
        getDashboardAllProjectData();
        getClientData();
        getUserList();
        setFilterProjects(null);
        getHistogramData();
    }, []);

    const defaultValues = { clientName: "", productionLocation: "", projectManager: "", projectObject: "", projectEndDate: "" };
    const form = useForm({ defaultValues });
    const { reset } = form;

    const getClientData = () => {
        setIsLoading(true);
        axiosInstance
            .get(constants.APQP_UPL.CLIENT_REGISTRY + "?page=1&limit=1000&sort_by=-created_at")
            .then((resp) => {
                setClientList(resp?.data?.data?.map((item) => {
                    return {
                        _id: item._id, customerName: item.customerName,
                    }
                }));
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const formatDate = (value) => {
        var date = value ? new Date(value) : null;
        return date?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });

    }
    const getUserList = () => {
        setIsLoading(true);
        axiosInstance
            .get(constants.URL.USERS + "?page=1&limit=1000&sort_by=-created_at")
            .then((resp) => {
                setUserList(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const productionLocation = ["India", "France", "Tunisia", "Macedonia", "Mexico", "China"];
    const projectObject = ["Creation", "Modification", "Duplication", "Transfer"];

    const getDashboardData = () => {
        setIsLoading(true);
        axiosInstance
            .get(constants.APQP_UPL.PROJECTS_OVERALL_COUNT + "?" + `projectManager=&productionLocation=&client_id=&projectObject=`)
            .then((resp) => {
                setRecords(resp?.data?.data);
                reset();
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getHistogramData = (data) => {
        console.log(data);
        
        setIsLoading(true);
        axiosInstance
            .get(constants.APQP_UPL.PROJECT_HISTOGRAM + "?" + `projectName=${(projectName)}&projectStatus=${(projectStatus)}&productionLocation=${(data?.productionLocation ? data?.productionLocation : PL)}&client_id=${(data?.clientName ? data?.clientName : CN)}&projectObject=${(data?.projectObject || PO || '')}&projectManager=${(data?.projectManager || PM || '')}&projectEndDate=${(data?.projectEndDate ? data?.projectEndDate?.getFullYear() : '')}`)
            .then((resp) => {
                setHistogram(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getHistogramData();
    }, [projectName, projectStatus]);

    const onSubmit = (data) => {
        const { projectManager, productionLocation, clientName, projectObject, projectEndDate } = data;
        
        setIsLoading(true);
        axiosInstance
            .get(constants.APQP_UPL.PROJECTS_OVERALL_COUNT + "?" + `projectManager=${(projectManager)}&productionLocation=${(productionLocation)}&client_id=${(clientName)}&projectObject=${(projectObject)}&projectEndDate=${(data?.projectEndDate ? data?.projectEndDate?.getFullYear() : '')}`)
            .then((resp) => {
                setRecords(resp?.data?.data);
                setCN(clientName);
                setPL(productionLocation);
                setPO(projectObject);
                setPM(projectManager);
                setYEAR(projectEndDate);
                getHistogramData(data);
                // reset();
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getDashboardAllProjectData = () => {
        setIsLoading(true);
        axiosInstance
            .get(constants.APQP_UPL.PROJECTS)
            .then((resp) => {
                setProjectsData(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const filteredRecords = projectsData?.filter((item) => {
            const projectManagerMatch = PM ? item?.projectManager === PM : true;
            const productionLocationNameMatch = PL ? item?.productionLocation === PL : true;
            const clientNameMatch = CN ? item?.client_id?._id === CN : true;
            const projectObjectNameMatch = PO ? item?.projectObject === PO : true;
            const projectEndDateMatch = YEAR ? item?.projectEndDate?.includes(YEAR?.getFullYear()) : true;

            return projectManagerMatch && productionLocationNameMatch && projectObjectNameMatch && clientNameMatch && projectEndDateMatch
        })
        setFilteredProjectsRecords(filteredRecords);

        // Define a mapping of projectStatus to colors
        const statusColorMap = {
            "In-Progress": "#EFAF1E", // Yellow
            "Completed": "#5DDE32", // Green
            "Hold": "#B1B1B1", // Grey
            "Open": "#B5E0F8", // Blue
        };

        const documentStyle = getComputedStyle(document.documentElement);
        const labels = filteredRecords?.map(item => item.projectName);
        const datas = filteredRecords?.map(item => item.completedPercentage);
        const backgroundColors = filteredRecords?.map(item => statusColorMap[item.projectStatus] || "#B5E0F8"); // Default to light blue
        const textColor = documentStyle.getPropertyValue('--text-color');
        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'Completed Percentage',
                    data: datas,
                    fill: false,
                    borderDash: [5, 5],
                    tension: 0.4,
                    borderColor: '#B4B4B4',
                    backgroundColor: backgroundColors,
                    pointBorderColor: backgroundColors,
                    pointBackgroundColor: backgroundColors,
                    borderWidth: 1,
                    pointRadius: 5, // Increases the size of the points
                    pointHoverRadius: 7, // Increases the size of points on hover
                },
            ],
        };

        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    position: 'bottom', // Display legend at the bottom
                    labels: {
                        color: textColor
                    },
                    display: false,
                },
            },
            scales: {
                x: {
                    title: {
                        display: true, // Enable the label for X-axis
                        text: 'Project Names', // X-axis label text
                        color: "#212121", // Label color
                        font: {
                            size: 12, // Font size
                            weight: 600, // Font weight
                        },
                    },
                    grid: {
                        drawOnChartArea: false,
                    },
                },
                y: {
                    title: {
                        display: true, // Enable the label for Y-axis
                        text: 'Completion Percentage', // Y-axis label text
                        color: "#212121", // Label color
                        font: {
                            size: 12, // Font size
                            weight: 600, // Font weight
                        },
                    },
                    beginAtZero: true,
                    grid: {
                        drawOnChartArea: false,
                    },
                },
            },
        };

        setChartData(data);
        setChartOptions(options);
    }, [projectsData, CN, PL, PO, PM, YEAR]);

    const handleFilterProject = (value) => {
        const data = {
            cardValue: value, projectManager: PM, productionLocation: PL, clientName: CN, projectObject: PO, projectEndDate: YEAR ? (YEAR?.getFullYear()) : ""
        }
        setFilterProjects(data);
        history.replace("/app/projects-projects");

    }

    const handleClear = () => {
        setCN('');
        setPL('');
        setPO();
        setPM();
        setYEAR();
    }

    const [projectNameFilter, setProjectNameFilter] = useState(''); 
    const [projectStatusFilter, setProjectStatusFilter] = useState(null);

    const projectStatusOptions = ['Open', 'Hold', 'In-Progress', 'Completed'];

    const filteredData =  (histogram || []).filter((item) => {
        const matchesName = projectNameFilter
            ? item.projectName.toLowerCase().includes(projectNameFilter.toLowerCase())
            : true;
        const matchesStatus = projectStatusFilter
            ? item.projectStatus.toLowerCase().includes(projectStatusFilter.toLowerCase())
            : true;
        return matchesName && matchesStatus && histogram;
    });
    

        const renderHeader = () => {
             return (
            <div className='flex justify-content-end gap-2 align-items-center'>
                <div className="">
                    <InputText type='search' value={projectNameFilter} onChange={(e)=> setProjectNameFilter(e.target.value)} placeholder="Search Project Name" className="" />
                </div>
                <div className="">
                    <Dropdown value={projectStatusFilter} onChange={(e) => setProjectStatusFilter(e.value)} options={projectStatusOptions} 
                    showClear placeholder="Search Milestone Status" className="w-full" style={{minWidth: '200px'}} />
                </div>
            </div>
             );
         };
     
         const header = renderHeader();

    return (
        <>
            <Toast ref={toast} />
            <h3 className="PS-title m-0">Dashboard</h3>
            <Divider />
            <div>
                <div className='col-12'>
                    <div className='grid sticky-header'>
                        <div className='col-12'>
                            <div className="card">
                                <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
                                    <div className='flex gap-4 col-12 sm:flex-container md:flex-row m-0 p-0 sm:flex-column dash-drop-3'>
                                        <div className="w-full">
                                            <span className="w-full">
                                                <Controller
                                                    name="clientName"
                                                    control={form.control}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown filter options={clientList} optionLabel="customerName" optionValue="_id" value={field.value || ""} placeholder="Customer" onChange={(e) => { field.onChange(e.value); }} style={{ width: '100%' }} />
                                                    )}
                                                />
                                            </span>
                                        </div>
                                        <div className="w-full">
                                            <span className="w-full">
                                                <Controller
                                                    name="projectManager"
                                                    control={form.control}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown filter value={field.value || ""} onChange={(e) => field.onChange(e.target.value)}
                                                            options={userList} optionLabel="first_name" optionValue='first_name' placeholder="Project Manager" style={{ width: '100%' }} />
                                                    )}
                                                />
                                            </span>
                                        </div>
                                        <div className="w-full">
                                            <span className="w-full">
                                                <Controller
                                                    name="productionLocation"
                                                    control={form.control}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown id='productionLocation' options={productionLocation} value={field.value || ""} placeholder="Production Location"
                                                            onChange={(e) => field.onChange(e.target.value)} style={{ width: '100%' }} />
                                                    )}
                                                />
                                            </span>
                                        </div>
                                        <div className="w-full">
                                            <span className="w-full">
                                                <Controller
                                                    name="projectObject"
                                                    control={form.control}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown options={projectObject} value={field.value || ""} placeholder="Object"
                                                            onChange={(e) => field.onChange(e.target.value)} style={{ width: '100%' }} />
                                                    )}
                                                />
                                            </span>
                                        </div>
                                        <div className="w-full">
                                            <span className="w-full">
                                                <Controller
                                                    name="projectEndDate"
                                                    control={form.control}
                                                    render={({ field, fieldState }) => (
                                                        <Calendar id={field.name} dateFormat="yy" view="year" value={field.value || ""} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} showIcon placeholder="Year" style={{ width: '100%' }} />
                                                    )}
                                                />
                                            </span>
                                        </div>
                                        <div className='project_search flex justify-content-end w-full align-items-center' style={{ marginRight: '5px' }}>
                                            <Button className="btn-black-user mx-2" label="Clear" onClick={() => { form.reset({ clientName: "", projectManager: "", productionLocation: "", projectObject: "", projectEndDate: "" }, { keepErrors: false, }); getDashboardData(); handleClear(); }} />
                                            <Button size="small" loading={isLoading} label="Search" className="btn-yellow-user" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className='grid' >
                        <div className='col-12 md:col-6 lg:col-3'>
                            <div onClick={() => handleFilterProject("")} className='flex hod-Project'>
                                <div style={{ width: '60%' }}>
                                    <div className='small-cards text-center' >
                                        <h2 className='dash_pro_stats_value'>{records?.totalProject}</h2>
                                        <h5 className='dash_pro_stats_title'>Total Projects</h5>
                                    </div>
                                </div>
                                <div className='admin-dash-image' style={{ width: '40%' }}>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 md:col-6 lg:col-3'>
                            <div onClick={() => handleFilterProject("In-Progress")} className='hod-Project flex'>
                                <div style={{ width: '60%' }}>
                                    <div className='small-cards text-center'>
                                        <h2 className='dash_pro_stats_value'>{records?.onGoingProjects}</h2>
                                        <h5 className='dash_pro_stats_title mb-0'>On Going Projects</h5>
                                    </div>
                                </div>

                                <div className='admin-dash2-image' style={{ width: '40%' }}>
                                    {/* <img src={Icon1} alt='icon1' /> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-12 md:col-6 lg:col-3'>
                            <div onClick={() => handleFilterProject("Completed")} className='hod-Project flex'>
                                <div style={{ width: '60%' }}>
                                    <div className='small-cards text-center'>
                                        <h2 className='dash_pro_stats_value '>{records?.completedProjects}</h2>
                                        <h5 className='dash_pro_stats_title mb-0'>Completed Projects</h5>
                                    </div>
                                </div>
                                <div className='admin-dash3-image' style={{ width: '40%' }}>
                                    {/* <img src={Group} alt='group' /> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-12 md:col-6 lg:col-3'>
                            <div onClick={() => handleFilterProject("Hold")} className='hod-Project flex'>
                                <div style={{ width: '60%' }}>
                                    <div className='small-cards text-center'>
                                        <h2 className='dash_pro_stats_value '>{records?.holdProjects}</h2>
                                        <h5 className='dash_pro_stats_title mb-0'>Hold Projects</h5>
                                    </div>
                                </div>
                                <div className='admin-dash4-image' style={{ width: '40%' }}>
                                    {/* <img src={Group} alt='group' /> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='grid mt-3'>
                        <div className='col-12'>
                            <div className="card">
                             <h3 className="PS-title m-0">Project Completion</h3>
                            <Divider />
                                <div className='flex justify-content-end align-items-center mb-3'>
                                    <div className='on-going-projects'></div><span className='status'>On Going Projects</span>
                                    <div className='completed-projects'></div><span className='status'>Completed Projects</span>
                                    <div className='hold-projects'></div><span className='status'>Hold Projects</span>
                                    <div className='open-projects'></div><span className='status'>Open</span>
                                </div>
                        
                                
                                <div className='flex justify-content-center'>
                                    <Chart type="line" data={chartData} options={chartOptions} className="w-full" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='grid mt-3'>
                        <div className='col-12'>
                            <div className='histogram-sticky'>
                                <div className='align-items-center flex justify-content-between text-center'>
                                    <h3 className="PS-title m-0">Project Progress Histogram</h3>
                                    <div>
                                        {header}
                                    </div>
                                </div>
                                <Divider />
                            </div>
                            <div className="col-12">
                                <DataTable tableStyle={{ minWidth: '50rem' }} value={filteredData}   rows={10} paginator loading={isLoading} >
                                    <Column field="projectName" header="Project Name" className='user-project-header' bodyClassName="user-project-bodyColumn"
                                    bodyStyle={{padding:'0px'}}
                                        body={(rowData) => (
                                            <div style={{ display: 'flex', alignItems: 'center', }}>
                                                <span
                                                    style={{
                                                        
                                                        borderLeft: "6px solid",
                                                        borderLeftColor:
                                                            rowData.projectRiskAssessment === "Red" ? "#CE0303" :
                                                            rowData.projectRiskAssessment === "Yellow" ? "#F3E900" :
                                                            rowData.projectRiskAssessment === "Green" ? "#26C10D" :
                                                            rowData.projectRiskAssessment === "Orange" ? "#FFA500" : "#FFFFFF",
                                                        height: '35px',
                                                        marginRight: '8px'
                                                    }}
                                                />
                                                {rowData.projectName}
                                            </div>
                                        )}> </Column>
                                    <Column field={(rowData) => rowData?.client_id?.customerName} header="Customer" className='user-project-header' bodyClassName="user-project-bodyColumn" style={{minWidth:'170px'}}></Column>
                                    <Column field="projectEndDate" header="Year" body={(rowData) => (<p>{formatDate(rowData.projectEndDate)}</p>)} className='user-project-header' bodyClassName="user-project-bodyColumn" style={{minWidth:'150px'}}></Column>
                                    <Column field="completedPercentage" header="Project Completion"
                                        body={(rowData) => (<div> {rowData?.completedPercentage !== undefined && rowData?.completedPercentage !== null ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(rowData.completedPercentage) + "%" : ""} </div>)}
                                        className='user-project-header' bodyClassName="user-project-bodyColumn"></Column>
                                    <Column field="productionLocation" header="Production Location" className='user-project-header' bodyClassName="user-project-bodyColumn"></Column>
                                    <Column field="projectStatus" header="Milestone Status" className='user-project-header' bodyClassName="user-project-bodyColumn" style={{minWidth:'150px'}}/>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <GanttChart toGantt={filteredProjectsRecords} />
                </div>
            </div>
        </>
    );
}

export default DashboardProjects;