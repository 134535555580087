import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Toast } from 'primereact/toast';
import constants from '../../../constants/constants';
import axios from 'axios';
import 'react-circular-progressbar/dist/styles.css';
import { Divider } from 'primereact/divider';
import { ProgressBar } from 'primereact/progressbar';
import { Chart } from 'primereact/chart';
import getHeaders from '../../../controllers/utils';
import { Carousel } from 'primereact/carousel';

const DashboardProjects = () => {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState();
    const [projectsData, setProjectsData] = useState();

    useEffect(() => {
        getDashboardData();
        getDashboardAllProjectData();
    }, []);

    const getDashboardData = () => {
        setIsLoading(true);
        axios
            .get(constants.APQP_UPL.PROJECTS_OVERALL_COUNT, { headers: getHeaders() })
            .then((resp) => {
                setRecords(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getDashboardAllProjectData = () => {
        setIsLoading(true);
        axios
            .get(constants.APQP_UPL.PROJECTS_OVERALL_DASHBOARD, { headers: getHeaders() })
            .then((resp) => {
                setProjectsData(resp?.data?.data?.allProjectsDashboard);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const formatDate = (value) => {
        var date = value ? new Date(value) : null;
        return date?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    }

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const labels = projectsData?.map(item => item.projectName);
        const datas = projectsData?.map(item => item.completedPercentage);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'Completed Percentage',
                    data: datas,
                    fill: false,
                    borderDash: [5, 5],
                    tension: 0.4,
                    borderColor: '#F3C72B ',
                    backgroundColor: '#F3C72B ',
                    borderWidth: 2,
                },
            ]
        };
        
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    position: 'bottom', // Display legend at the bottom
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    grid: {
                        drawOnChartArea: false,
                    }
                },
                y: {
                    beginAtZero: true,
                    grid: {
                        drawOnChartArea: false,
                    }
                },
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [projectsData]); // Add projectsData as a dependency to useEffect

    const productTemplate = (item) => {
        return (
            <div className='m-3'>
                <div className='dashboard_projects_status-cards p-4'>
                    <h2 className='projects_overview_task_completion_title'>{item?.projectName}</h2>
                    <div className='projects_overview_task_completion_value mb-0 flex justify-content-between'>
                        <span>Task Done</span>
                        <span>{item?.completedPercentage}%</span>
                    </div>
                    <ProgressBar className="my-2" showValue={false} value={item?.completedPercentage} style={{ height: '6px' }} pt={{ value: { style: { background: '#26C10D' } } }}></ProgressBar>
                    <div className='projects_overview_task_completion_value mb-0 flex justify-content-between pt-1'>
                        <span>End Date: {formatDate(item?.projectEndDate)}</span>
                        <span className='dpst-value'>{item?.completedCount}/{item?.sumOfSubTasksCount}</span>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <h3 className="PS-title m-0">Dashboard</h3>
            <Divider />
            <div className='grid'>
                <div className='col-12'>
                    <div className='my-3 grid'>
                        <div className='col-12 md:col-6 lg:col-3'>
                            <div className='dashboard_projects_status-cards p-4'>
                                <h2 className='dash_pro_stats_value dpsc_blue'>{records?.totalProject}</h2>
                                <h5 className='dash_pro_stats_title mb-0'>Total Projects</h5>
                            </div>
                        </div>
                        <div className='col-12 md:col-6 lg:col-3'>
                            <div className='dashboard_projects_status-cards dpsc_yellow p-4'>
                                <h2 className='dash_pro_stats_value'>{records?.onGoingProjects}</h2>
                                <h5 className='dash_pro_stats_title mb-0'>On Going Projects</h5>
                            </div></div>
                        <div className='col-12 md:col-6 lg:col-3'>
                            <div className='dashboard_projects_status-cards dpsc_green p-4'>
                                <h2 className='dash_pro_stats_value'>{records?.completedProjects}</h2>
                                <h5 className='dash_pro_stats_title mb-0'>Completed Projects</h5>
                            </div></div>
                        <div className='col-12 md:col-6 lg:col-3'>
                            <div className='dashboard_projects_status-cards dpsc_orange p-4'>
                                <h2 className='dash_pro_stats_value'>{records?.upcomingProjects}</h2>
                                <h5 className='dash_pro_stats_title mb-0'>Upcoming Projects</h5>
                            </div></div>
                    </div>

                    <div className='my-3 mx-1 card'>
                        <h3 className="PS-title m-0">Project Overview</h3>
                        <div className='my-3 grid'>
                            {projectsData && projectsData.length > 3 ?
                                <Carousel value={projectsData} numVisible={3} numScroll={3} itemTemplate={productTemplate} className='w-full' />
                                :
                                <>{projectsData && projectsData?.map((item) => {
                                    return (
                                        <div className='col-12 md:col-6 lg:col-4'>
                                            <div className='dashboard_projects_status-cards p-4'>
                                                <h2 className='projects_overview_task_completion_title'>{item?.projectName}</h2>
                                                <div className='projects_overview_task_completion_value mb-0 flex justify-content-between'>
                                                    <span>Task Done</span>
                                                    <span>{item?.completedPercentage}%</span>
                                                </div>
                                                <ProgressBar className="my-2" showValue={false} value={item?.completedPercentage} style={{ height: '6px' }} pt={{ value: { style: { background: '#26C10D' } } }}></ProgressBar>
                                                <div className='projects_overview_task_completion_value mb-0 flex justify-content-between pt-1'>
                                                    <span>End Date: {formatDate(item?.projectEndDate)}</span>
                                                    <span className='dpst-value'>{item?.completedCount}/{item?.sumOfSubTasksCount}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12'>
                    <div className="card">
                        <h3 className="PS-title m-0">Project Completion</h3>
                        <Divider />
                        <div className='flex justify-content-center'>
                            <Chart type="line" data={chartData} options={chartOptions} className="w-full" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardProjects;