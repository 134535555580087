import { action, persist} from "easy-peasy";
import { UserService } from "../service/userService";

let userService =  new UserService()

const loginModel = persist({
  user: userService.getUser() || null,
  
  setUser: action((state, payload) => {
    const updatedPayload = {
      ...payload,
      time: new Date()
    };
    userService.setUser(updatedPayload)
    state.user = updatedPayload;
    localStorage.setItem("e-portal-access-token", updatedPayload?.access_token);
  }),

  setLogOut: action((state, payload) => {
    userService.resetUser()
    state.user = null;
  }),
  
});

export default loginModel;