import { action, persist} from "easy-peasy";


const sipModel = persist({
  evaluationViewDatas: null,
  eventTabDatas: null,
  questionsData: null,
  evaluationId: null,

  setEvaluationViewDatas: action((state, payload) => {
    state.evaluationViewDatas = payload;
  }),

  setEventTabDatas: action((state, payload) => {
    state.eventTabDatas = payload;
  }),

  setQuestionsData: action((state, payload) => {
    console.log(payload);
    state.questionsData = payload;
  }),

  setEvaluationId: action((state, payload) => {
    state.evaluationId = payload;
  }),
  
});

export default sipModel;