import React, { useState, useEffect } from 'react';
import axios from 'axios';
import constants from '../../../constants/constants';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useStoreState } from 'easy-peasy';
import getHeaders from '../../../controllers/utils';
import { Controller, useForm } from "react-hook-form";
import { Card } from 'primereact/card';
import classNames from 'classnames';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputTextarea } from 'primereact/inputtextarea';

const ProjectTaskHeader = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const typeList = ["LV", "EV"];
    const [editValue, setEditValue] = useState();
    const projectViewDatas = useStoreState((actions) => actions.projectModel.projectViewDatas);
    const toast = useRef(null);
    console.log(props);

    useEffect(() => {
        if (props?.tochild) {
            setEditValue(props?.tochild);
            setValue("headerTitle", props?.tochild.headerTitle)
            // setValue("headerDescription", props?.tochild.headerDescription)
            // setValue("type", props?.tochild.type)
        }
    }, [props]);

    // const getCategory = () => {
    //     setIsLoading(true);
    //     axios
    //         .get(constants.APQP_UPL.TASK_HEADER + "/" + projectViewDatas?._id + "/headings", { headers: getHeaders() })
    //         .then((resp) => {
    //             setCategoryList(resp?.data?.data?.results);
    //             // setCount(resp?.data?.count);
    //         })
    //         .catch((e) => console.error(e))
    //         .finally(() => {
    //             setIsLoading(false);
    //         });
    // }

    const defaultValues = { headerTitle: "", headerDescription: "", type: "" };
    const form = useForm({ defaultValues });
    const { reset, setValue } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const handleTaskHeaderEdit = (item) => {
    }
    console.log(editValue);

    const onSubmit = (data) => {
        const payload = {
            headerTitle: data.headerTitle,
            // headerDescription: data.headerDescription,
            // type: data.type
        }  
        setIsLoading(true);
        if(!editValue){
        axios.post(constants.APQP_UPL.TASK_HEADER, payload, { headers: getHeaders() })
        .then((resp) => {
            // toast.current.show({ severity: "success", summary: "Success", detail: "Added Successfully" });
            reset();
            props.sendDataToParent("API Call & Redirection");
        }).catch((e) => {
            toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
            console.error(e);
        }).finally(() => {
            setIsLoading(false);
        })
        }else {
        axios.patch(constants.APQP_UPL.TASK_HEADER + "/" + editValue?._id, payload, { headers: getHeaders() })
        .then((resp) => {
            // toast.current.show({ severity: "success", summary: "Success", detail: "Updated Successfully" });
            setEditValue();
            reset();
            props.sendDataToParent("API Call & Redirection");
        }).catch((e) => {
            toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
            console.error(e);
        }).finally(() => {
            setIsLoading(false);
        })
        }
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const handleTaskDelete = (item) => {
        console.log(item);
        setIsLoading(true);
        axios.delete(constants.APQP_UPL.TASK_HEADER_TASK_HEADING + item?._id, { headers: getHeaders() })
            .then((resp) => {
                // console.log(resp?.data?.data);
                // getCategory();
                toast.current.show({ severity: "success", summary: "Confirmed", detail: "Deleted Successfully", life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const confirm = (item) => {
        console.log(item);
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleTaskDelete(item),
            reject,
            acceptClassName: "btn-black",
            rejectClassName: "btn-yellow",
            // draggable: false,
            // className: "confirm_dilog",
        });
    };

    const header = (
        <div className="head-1">
            <h2 className="head-2">Add Task Definition</h2>
        </div>
    );

    const customButtonTemplate = (item) => {
        return (
            <div className='flex justify-content-end gap-2'>
                <Button className="btn-black" label="Edit" onClick={() => handleTaskHeaderEdit(item)} />
                <Button className="btn-black" label="Delete" onClick={() => confirm(item)} />
            </div>
        );
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <Card header={header}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
                            <div className="p-fluid formgrid grid lr-gap">
                                <div className="field col-12 md:col-6 lg:col-6">
                                    <label htmlFor="headerTitle" className="col-6">
                                    Task Definition
                                    </label><span className="w-full">
                                        <Controller
                                            name="headerTitle"
                                            control={form.control}
                                            rules={{ required: "Header Title is required" }}
                                            render={({ field, fieldState }) => (
                                                <InputTextarea id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                            )}
                                        />
                                        {getFormErrorMessage("headerTitle")}</span>
                                </div>
                                {/* <div className="field col-12 md:col-6 lg:col-6">
                                    <label htmlFor="type" className="col-6">
                                        Type
                                    </label><span className="w-full">
                                        <Controller
                                            name="type"
                                            control={form.control}
                                            rules={{ required: "Type is required" }}
                                            render={({ field, fieldState }) => (
                                                <Dropdown options={typeList} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                            )}
                                        />
                                        {getFormErrorMessage("type")}</span>
                                </div> */}
                                {/* <div className="field col-12 md:col-6 lg:col-6">
                                    <label htmlFor="headerDescription" className="col-6">
                                        Task Description
                                    </label>
                                    <span className="w-full">
                                        <Controller name="headerDescription" control={form.control}
                                            rules={{ required: "Task Description is required" }}
                                            render={({ field, fieldState }) => (
                                                <InputTextarea id='headerDescription' value={field.value} onChange={(e) => field.onChange(e.target.value)}
                                                    placeholder="" className={classNames({ "p-invalid": fieldState.error })} />
                                            )}
                                        />
                                        {getFormErrorMessage("headerDescription")}</span>
                                </div> */}
                            </div>
                            <div className="flex justify-content-end">
                                <Button icon="pi pi-check" size="small" loading={isLoading} label="Save" className="btn-yellow" />
                            </div>
                        </form>
                        {/* {categoryList && categoryList?.length > 0 ? <hr className="hr-line mt-3"></hr> : null}
                        {categoryList && categoryList?.map((item) => {
                            return (
                                <div className='flex align-items-center justify-content-between mb-2'>
                                    <div className='header-list'>{item?.headerTitle}</div>
                                    <div>{customButtonTemplate(item)}</div>
                                </div>
                            )
                        })} */}
                    </Card>
                </div>
            </div>
        </div>
    )
}
export default ProjectTaskHeader
