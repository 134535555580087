import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import constants from "../../../constants/constants";
import "../../../assets/css/Project.css";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Card } from 'primereact/card';
import { Calendar } from 'primereact/calendar';
import { Controller, useForm } from "react-hook-form";
import { Toast } from "primereact/toast";
import getHeaders from "../../../controllers/utils";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { useStoreState, useStoreActions} from "easy-peasy";

const AddProjects = ({sendDataToParent}) => {
  const history = useHistory();
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState();
  const [clientList, setClientList] = useState();
  // const [addProjects, setAddProjects] = useState(false);
  const [projectList, setProjectList] = useState();
  const [userList, setUserList] = useState();
  const [customerName, setCustomerName] = useState(null);
  const user = useStoreState((actions) => actions.loginModel.user); 
  const projectEditValue = useStoreState((actions) => actions.projectModel.projectEditValue);
  const setProjectEditValue = useStoreActions((actions) => actions.projectModel.setProjectEditValue);
  const setAddProjects = useStoreActions((actions) => actions.projectModel.setAddProjects);
  const addProjects = useStoreState((actions) => actions.projectModel.addProjects);
  let minDate = new Date();

  useEffect(() => {
    getClientData();
    getProjectData();
    getUserList();
}, []);

const defaultValues = { projectName: "", startOfProduction: "", modelNumber: "", startDate: "", clientName: "", modelName: "", productionLocation: "", endDate: "", projectManager: "", salesMan: "", ppapDate: "", ppapByFILECDate: "", projectObject: "", projectRiskAssessment: "", affairNumber: "" };
const form = useForm({ defaultValues });
const { reset, setValue } = form;
const errors = form.formState.errors;
const getFormErrorMessage = (name) => {
  return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
};

useEffect(() => {
  if (projectEditValue) {
    setValue("projectName", projectEditValue?.projectName)
          setValue("startOfProduction", new Date(projectEditValue?.startOfProduction))
          setValue("modelNumber", projectEditValue?.modelNumber)
          setValue("clientName", projectEditValue?.client_id?._id)
          setValue("modelName", projectEditValue?.modelName)
          setValue("productionLocation", projectEditValue?.productionLocation)
          setValue("projectManager", projectEditValue?.projectManager)
          setValue("salesMan", projectEditValue?.salesMan)
          setValue("ppapDate", new Date(projectEditValue?.ppapDate))
          setValue("ppapByFILECDate", new Date(projectEditValue?.ppapByFILECDate))
          setValue("projectObject", projectEditValue?.projectObject)
          setValue("affairNumber", projectEditValue?.affairNumber)
          setValue("projectRiskAssessment", projectEditValue?.projectRiskAssessment)
  }
},[projectEditValue]);

console.log(clientList);
  const onSubmit = (data) => {
    const payload = {
      projectName: data.projectName,
      startOfProduction: data.startOfProduction,
      modelNumber: data.modelNumber,
      client_id: data.clientName,
      modelName: data.modelName,
      productionLocation: data.productionLocation,
      projectCreatedBy: user?.user?._id,
      projectManager: data.projectManager,
      salesMan: data.salesMan,
      ppapDate: data.ppapDate,
      ppapByFILECDate: data.ppapByFILECDate,
      projectObject: data.projectObject,
      affairNumber: data.affairNumber,
      projectRiskAssessment: data.projectRiskAssessment,
    }
    console.log(payload);
    
    setIsLoading(true);
    if(projectEditValue){
      axios.patch(constants.APQP_UPL.PROJECTS + "/" + projectEditValue?._id, payload, { headers: getHeaders() })
      .then((resp) => {
        console.log(resp);
        toast.current.show({ severity: "success", summary: "Success", detail: "Project Updated Successfully" });
        reset();
        setAddProjects(false);
        setProjectEditValue(null);
        sendDataToParent("Call"); 
      }).catch((e) => {
        toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
        console.error(e);
      }).finally(() => {
        setIsLoading(false);
      })
    }else {
        axios.post(constants.APQP_UPL.PROJECTS, payload, { headers: getHeaders() })
          .then((resp) => {
            // console.log(resp?.data?.data);
            toast.current.show({ severity: "success", summary: "Success", detail: "Added Successfully" });
            reset();
            setAddProjects(false);
            sendDataToParent("Call"); 
          }).catch((e) => {
            toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
            console.error(e);
          }).finally(() => {
            setIsLoading(false);
          })
    }

  };
    
  useEffect(() => {
      if (customerName) {
        let customer_Name = clientList.find((item) => item._id === customerName)
          setValue("modelNumber", customer_Name?.modelNumber)
          setValue("modelName", customer_Name?.modelName)
          // setValue("yearlyVolume", customer_Name?.yearlyVolume)
          setValue("productionLocation", customer_Name?.productionLocation)
      }
  }, [customerName]);


const getClientData = () => {
    setIsLoading(true);
    axios
        .get(constants.APQP_UPL.CLIENT_REGISTRY + "?page=1&limit=1000&sort_by=-created_at", { headers: getHeaders() })
        .then((resp) => {
            setClientList(resp?.data?.data?.map((item)=> {
              return {
                _id: item._id, customerName: item.customerName,
              }
            }));
          // getProjectById();
        })
        .catch((e) => console.error(e))
        .finally(() => {
            setIsLoading(false);
        });
}

const getUserList = () => {
  setIsLoading(true);
  axios
    .get(constants.URL.USERS + "?page=1&limit=1000&sort_by=-created_at", { headers: getHeaders() })
    .then((resp) => {
      setUserList(resp?.data?.data);
    })
    .catch((e) => console.error(e))
    .finally(() => {
      setIsLoading(false);
    });
}

const getProjectData = () => {
  setIsLoading(true);
  axios
      .get(constants.APQP_UPL.PROJECTS + "?page=1&limit=1000&sort_by=-created_at", { headers: getHeaders() })
      .then((resp) => {
          setProjectList(resp?.data?.data);
      })
      .catch((e) => console.error(e))
      .finally(() => {
          setIsLoading(false);
      });
}


  const header = (
    <div className="head-1">
       {!projectEditValue ?
      <h2 className="head-2">Add Project</h2>
      :
      <h2 className="head-2">Edit Project</h2>
       }
    </div>
  );

  const productionLocation = ["India", "France", "Tunisia", "Macedonia", "Mexico", "China"];

  const projectObject = ["Creation", "Modification", "Duplication", "Transfer"];

  const projectRiskAssessment = ["Low Risk", "High Risk"];

  return (
    <div>
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12">
          <Card header={header}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
              <div className="p-fluid formgrid grid lr-gap">
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="projectName">
                  Project Name<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="projectName"
                      control={form.control}
                      rules={{ required: "Project Name is required." }}
                      render={({ field, fieldState }) => (
                        <InputText filter id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                      )}
                    />
                    {getFormErrorMessage("projectName")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="startOfProduction">
                  Start of Production(SOP)<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="startOfProduction"
                      control={form.control}
                      rules={{ required: "Start of Production(SOP) is required." }}
                      render={({ field, fieldState }) => (
                        <Calendar id={field.name} dateFormat="dd/mm/yy" value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} showIcon />
                      )}
                    />
                    {getFormErrorMessage("startOfProduction")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="clientName">
                  Customer Name<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="clientName"
                      control={form.control}
                      rules={{ required: "Client Name is required." }}
                      render={({ field, fieldState }) => (
                        <Dropdown filter options={clientList} optionLabel="customerName" optionValue="_id" value={field.value} placeholder="" className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => {field.onChange(e.target.value)}} />
                      )}
                    />
                    {getFormErrorMessage("clientName")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="projectManager">
                  Project manager<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="projectManager"
                      control={form.control}
                      rules={{ required: "Project Manager is required." }}
                      render={({ field, fieldState }) => (
                        // <Dropdown filter options={clientList} optionLabel="customerName" optionValue="_id" id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => {field.onChange(e.target.value); setCustomerName(e.target.value)}} />
                        <Dropdown filter value={field.value} onChange={(e) => field.onChange(e.target.value)}
                        options={userList} optionLabel="first_name" optionValue='first_name' placeholder="" className={classNames({ "p-invalid": fieldState.error })} />
                      )}
                    />
                    {getFormErrorMessage("projectManager")}</span>
                </div> 
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="salesMan">
                  Salesman<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="salesMan"
                      control={form.control}
                      rules={{ required: "Salesman is required." }}
                      render={({ field, fieldState }) => (
                        // <Dropdown filter options={clientList} optionLabel="customerName" optionValue="_id" id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => {field.onChange(e.target.value); setCustomerName(e.target.value)}} />
                        <Dropdown filter  value={field.value} onChange={(e) => field.onChange(e.target.value)}
                        options={userList} optionLabel="first_name" optionValue='first_name' placeholder="" className={classNames({ "p-invalid": fieldState.error })} />
                      )}
                    />
                    {getFormErrorMessage("salesMan")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="ppapDate">
                  PPAP Presentation Date<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="ppapDate"
                      control={form.control}
                      rules={{ required: "PPAP presentation Date is required." }}
                      render={({ field, fieldState }) => (
                        <Calendar showIcon id={field.name} dateFormat="dd/mm/yy" value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)}/>
                        // <Calendar showIcon minDate={minDate} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                      )}
                    />
                    {getFormErrorMessage("ppapDate")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="ppapByFILECDate">
                  PPAP Presentation By FILEC Date<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="ppapByFILECDate"
                      control={form.control}
                      rules={{ required: "PPAP presentation confirmed by FILEC Date is required." }}
                      render={({ field, fieldState }) => (
                        <Calendar showIcon id={field.name} minDate={minDate} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                      )}
                    />
                    {getFormErrorMessage("ppapByFILECDate")}</span>
                </div>
                {/* <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="modelNumber">
                  Model Number<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="modelNumber"
                      control={form.control}
                      // rules={{ required: "Model Number is required" }}
                      render={({ field, fieldState }) => (
                        <InputText id={field.name} readOnly value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                      )}
                    />
                    {getFormErrorMessage("modelNumber")}</span>
                </div> */}
                {/* <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="modelName">
                  Model Name<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="modelName"
                      control={form.control}
                      // rules={{ required: "Model Name is required" }}
                      render={({ field, fieldState }) => (
                        <InputText readOnly id={field.name} value={field.value} onChange={(e) => field.onChange(e.target.value)}
                        className={classNames({ "p-invalid": fieldState.error })} />
                      )}
                    />
                    {getFormErrorMessage("modelName")}</span>
                </div> */}
                {/* <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="yearlyVolume">
                  Yearly Volume<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="yearlyVolume"
                      control={form.control}
                      render={({ field, fieldState }) => (
                        <InputText readOnly id={field.name} value={field.value} onChange={(e) => field.onChange(e.target.value)}
                        className={classNames({ "p-invalid": fieldState.error })} />
                      )}
                    />
                    {getFormErrorMessage("yearlyVolume")}</span>
                </div> */}
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="productionLocation">
                 Production Location<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="productionLocation"
                      control={form.control}
                      rules={{ required: "Location is required" }}
                      render={({ field, fieldState }) => (
                        <Dropdown id='productionLocation'  options={productionLocation}  value={field.value} placeholder=""
                        className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                      )}
                    />
                    {getFormErrorMessage("productionLocation")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="affairNumber">
                  Affair Number<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="affairNumber"
                      control={form.control}
                      rules={{ required: "Affair Number is required." }}
                      render={({ field, fieldState }) => (
                        <InputText filter id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                      )}
                    />
                    {getFormErrorMessage("affairNumber")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="projectObject">
                 Object<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="projectObject"
                      control={form.control}
                      rules={{ required: "Object is required" }}
                      render={({ field, fieldState }) => (
                        <Dropdown options={projectObject}  value={field.value} placeholder=""
                        className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                      )}
                    />
                    {getFormErrorMessage("projectObject")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="projectRiskAssessment">
                Project Risk Assessment<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="projectRiskAssessment"
                      control={form.control}
                      rules={{ required: "Project Risk Assessment is required" }}
                      render={({ field, fieldState }) => (
                        <Dropdown id='projectRiskAssessment'  options={projectRiskAssessment}  value={field.value} placeholder=""
                        className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                      )}
                    />
                    {getFormErrorMessage("projectRiskAssessment")}</span>
                </div>
              </div>
                <div className="flex justify-content-end px-4">
                    <Button icon="pi pi-check" size="small" loading={isLoading} label="Save" className="btn-yellow"  />
                </div>
            </form>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default AddProjects;
