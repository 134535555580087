import { action, persist} from "easy-peasy";


const projectModel = persist({
  projectViewDatas: null,
  projectViewScreen: false,

  setProjectViewDatas: action((state, payload) => {
    state.projectViewDatas = payload;
  }),
  setProjectViewScreen: action((state, payload) => {
    state.projectViewScreen = payload;
  }),
  
});

export default projectModel;