import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from "primereact/button";
import constants from "../../../constants/constants";
import getHeaders from "../../../controllers/utils";

const AllClientsList = () => {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState();
    const setActiveIndex = useStoreActions((actions) => actions.tabModel.setActiveIndex);
    const setEmployeeEditValue = useStoreActions((actions) => actions.tabModel.setEmployeeEditValue);

    useEffect(() => {
        getUserList();
        setEmployeeEditValue(null);
    }, []);

    const getUserList = () => {
        setIsLoading(true);
        axios
            .get(constants.APQP_UPL.CLIENT_REGISTRY + "?page=1&limit=1000&sort_by=-created_at", { headers: getHeaders() })
            .then((resp) => {
                setRecords(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleTaskDelete = (item) => {
        setIsLoading(true);
        axios.delete(constants.APQP_UPL.CLIENT_REGISTRY + "/" + item?._id, { headers: getHeaders() })
            .then((resp) => {
                // console.log(resp?.data?.data);
                getUserList();
                toast.current.show({ severity: "success", summary: "Confirmed", detail: "Deleted Successfully", life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const confirm = (item) => {
        console.log(item);
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleTaskDelete(item),
            acceptClassName: "btn-black",
            rejectClassName: "btn-yellow",
            draggable: false,
            // className: "confirm_dilog",
        });
    };

    const IconBodyTemplate = (item) => {
        return (
            <div className='flex justify-content-end gap-2'>
                <Button className="btn-black" label="Edit" onClick={() => {setActiveIndex(0); setEmployeeEditValue(item)}} />
                <Button className="btn-black" label="Delete" onClick={() => confirm(item)} />
            </div>
        );
    };

    const statusBodyTemplate = (item) => {
        let statusStyle;
        switch (item.status) {
            case false:
                statusStyle = { color: '#486600', background: '#ECFCC0' };
                break;
            case true:
                statusStyle = { color: '#DF5D40', background: '#FFDBD4' };
                break;
            default:
                statusStyle = { color: 'black', background: '#F8F8F8' };
        }
        return <div style={statusStyle} className='task-status'>{item.status === false ? "Active" : "In Active"}</div>;
    };

    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="grid">
                <div className="col-12">
                    <div className="">
                            {records?.map((item) => {
                                return (
                                    <div className="card flex justify-content-between">
                                        {/* <div className='PS-card-key'>
                                            <h4>Customer ID</h4>
                                            <p>{item?.customerID}</p>
                                        </div> */}
                                        <div className='col-2 PS-card-key'>
                                            <h4>Customer Name</h4>
                                            <p>{item?.customerName}</p>
                                        </div>
                                        <div className='col-2 PS-card-key text-center'>
                                            <h4>Country</h4>
                                            <p>{item?.country}</p>
                                        </div>
                                        <div className='col-2 PS-card-key text-center'>
                                            <h4>Legal Entity</h4>
                                            <p>{item?.legalEntity}</p>
                                        </div>
                                        <div className='col-2 PS-card-key text-center'>
                                            <h4>Incoterm</h4>
                                            <p>{item?.incoterm}</p>
                                        </div>
                                        <div className='col-2 PS-card-key text-center'>
                                            <h4>VAT Number</h4>
                                            <p>{item?.vatNumber}</p>
                                        </div>
                                        <div className='col-2 PS-card-key'>
                                            <div>{IconBodyTemplate(item)}</div>
                                        </div>
                                    </div>
                                )
                            })}
                            {records && records?.length > 0 ? null : "No Data found"}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllClientsList;
