import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { useStoreState } from 'easy-peasy';
import constants from '../constants/constants';

const Dashboard = () => {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState();
    const user = useStoreState((actions) => actions.loginModel.user);

    useEffect(() => {
        getSchedule()
    }, []);

    const getSchedule = () => {
        setIsLoading(true);
        axios
            .get(constants.URL.ADMIN_DASHBOARD, { headers: { "x-access-token": user?.token } })
            .then((resp) => {
                setRecords(resp?.data?.results);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <>
            <Toast ref={toast} />
            <div className='card'>
                <h4 className='mb-0 font-bold'>Dashboard</h4>
            </div>
        </>

    );
}

export default Dashboard;