import "react-app-polyfill/ie11";
import React from "react";
import App from "./App";
import { HashRouter, Route, Switch } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { useStoreState } from "easy-peasy";
import Login from "./pages/Login";
import "./assets/css/Project.css"
import CustomRoute from "./CustomRoute";

function Main() {
    const user = useStoreState((actions) => actions.loginModel.user);

    return (
        <HashRouter>
            <Switch>
                <ScrollToTop>
                    <Route exact path="/" component={Login} />
                    {/* <Route path="/app" component={App} /> */}
                    <CustomRoute path="/app" component={App} 
                        allowedRoles={["admin", "user"]} currentUserRoles={user?.user?.role} />
                </ScrollToTop>
            </Switch>
        </HashRouter>
    );
}

export default Main;
