const API_URL = "https://amphenolapqpapi.clanizon.com/"  //ampheserver
// const API_URL = "http://localhost:8080/"

const BASE_URL = API_URL + "api/";

const constants = {
    URL: {
        LOGIN: BASE_URL + "/auth/login",
        PROJECTS_TASK_HEADING: BASE_URL + "projects/headings/",
        TASK: BASE_URL + "projects/tasks",
        USERS: BASE_URL + "users",
        SKILL_SET_HEADING: BASE_URL + "skillsetHeading",
        SKILL_SET_ITEMS: BASE_URL + "skillsetitems",
        RELEASE_SKILL_SET: BASE_URL + "releaseSkillset",
        GAP_ANALYSIS: BASE_URL + "filterMembersBySkillsetItem/gapAnalysis",
        LOW_SCORE_GAP_ANALYSIS: BASE_URL + "/lowScore/gapAnalysis",
        GAP_ANALYSIS_MEMBERS: BASE_URL + "filterMember/gapAnalysis",
        EVALUATION: BASE_URL + "evaluation",
        SCHEDULE_GAP_ANALYSIS: BASE_URL + "eventSchedule",
        EVENT: BASE_URL + "eventSchedule",
        QUESTION: BASE_URL + "testQuestionEvent",
        RESET_GAP_ANALYSIS: BASE_URL + "releaseSkillset/",
        GET_ATTENDEES: BASE_URL + "eventAttendee/filterSchedule/",
        RELEASE_QUESTION: BASE_URL + "releaseTest",
        TEST_SCORE: BASE_URL + "scoreCalculation/filterByScheduleId/",
        SKILL_MATRIX: BASE_URL + "evaluation/SkillMatrix",
        EVENT_QUESTION: BASE_URL + "testQuestionEvent/ScheduleId/",
        FEEDBACK_ADMIN_QUEUE: BASE_URL + "adminqueue",
        FEEDBACK_ADMIN_ASSIGNED_TO_HOD: BASE_URL + "assigned-hodfeedback",
        FEEDBACK_STATUS: BASE_URL + "feedbackstatus_Admin",
        FEEDBACK_SUBMITTED: BASE_URL + "submitted-feedback_Admin",
        DOWNLOAD_FEEDBACK_STATUS: BASE_URL + "download-feedbacks-excel",
        GET_BY_EVENT_SCHEDULED_FEEDBACK: BASE_URL + "getByEventScheduleId/",
        EFFECTIVENESS_STATUS_UPDATE: BASE_URL + 'effectivenessStatus',
        GET_EFFECTIVENESS_BY_SCHEDULE_ID: BASE_URL + 'getByEvtScheduleId/',
        ALL_PROJECT_DOWNLOAD: BASE_URL + 'downloadOverAllProjects',
        PARTICULAR_PROJECT_DOWNLOAD: BASE_URL + 'downloadParticularProject',

        
        GET_HOD_FEEDBACK_STATUS: BASE_URL + "feedbackstatus_hod",
        GET_HOD_FEEDBACK_QUEUE: BASE_URL + "hodqueue",
        GET_HOD_EFFECTIVENESS: BASE_URL + "evtScheduleByHODID/",
        GET_HOD_EFFECTIVENESS_BY_HOD_ID: BASE_URL + "getByEvtScheduleHODId/",

        
        TASK_STATUS_UPDATE: BASE_URL + "projects/tasks/",
        ATTENDEES_BY_ID: BASE_URL + "eventAttendee/filter/",
        PUNCHIN: BASE_URL + "eventAttendee/",
        GET_TEST_DATAS: BASE_URL + "scoreCalculation/filterByTestAttendeeId/",
        TEST: BASE_URL + "scoreCalculation/updateAnswer/",
        EVALUATION_FILTER_BY_ID: BASE_URL + "evaluation/filterByRepotingId/",
        LATEST_RELEASE_EVALUATION_BY_ID: BASE_URL + "evaluation/getByLastReleaseseHODId/",
        OLDEST_RELEASE_EVALUATION_BY_ID: BASE_URL + "evaluation/getByOldReleaseseHODId/",
        EVALUATION_SCORE_UPDATE: BASE_URL + "evaluation/updateHODScore/",
        GET_USER_BY_DEPARTMENT: BASE_URL + "department/",
        GET_META_STATUS: BASE_URL + "getmetastatus",
        ASSIGNED_TO_FEEDBACK: BASE_URL + "assigned-feedback",
        METADATA_DEPARTMENT: BASE_URL + "department-metadata",
        METADATA_DESIGNATION: BASE_URL + "designation-metadata",
        ASSIGNED_TO_FEEDBACK_BY_USER: BASE_URL + "assigned-feedback",
        USER_FEEDBACK_STATUS_UPDATE: BASE_URL + "userfeedback/",
        EVENT_FEEDBACK_POST: BASE_URL + "eventFeedback",
        EVENT_EFFECTIVENESS_POST: BASE_URL + "eventEffectiveness",


        // user
        GET_BY_EVENT_SCHEDULE_BY_ID: BASE_URL + "getByEventScheduleId/",
    },
    APQP_UPL: {
        CLIENT_REGISTRY: BASE_URL + "clientRegistry",
        TASK_HEADER: BASE_URL + "taskHeader",
        SUB_TASKS: BASE_URL + "subTasks",
        PROJECTS: BASE_URL + "project",
        PROJECTS_DASHBOARD: BASE_URL + "projectDashboard",
        PROJECTS_OVERALL_COUNT: BASE_URL + "overAllCards",
        PROJECTS_OVERALL_DASHBOARD: BASE_URL + "allProjectDashboard",
        UPLOAD_ATTACHMENTS: BASE_URL + "file/upload-multiplePDFS",

        PROJECT_ASSIGNED_BY_USER_ID:BASE_URL + "projSubTaskByAssignedTo/"
    }
}

export default constants